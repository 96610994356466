//formations
.a2fm-formations-filters {
  margin-bottom: 32px;
}

//.une-formation .card-img-top {
//  //background:url('../img/autre.jpeg') no-repeat;
//  background-size:cover;
//  height:200px;
//  width:100%;
//  background-position:center;
//}
.une-formation.dpc-non-presentiel .card-img-top {
  //background:url('../img/faf.jpeg') no-repeat;
  background-size: cover;
  background-position: center;
}

//.une-formation:not(.une-formation-color):not(.une-formation-intervenant) {
//  .card::after{
//    content: '';
//    width: 97%;
//    height: 7px;
//    background: #04afde;
//    display: block;
//    position: absolute;
//    left: 1.5%;
//    bottom: 0.2px;
//    border-bottom-left-radius: 100px;
//    border-bottom-right-radius: 100px;
//  }
//}

.une-formation.dpc {
  .card .btn, td .btn {
    background: $c-light-eggplant;
  }

  .card .btn-outline, td .btn-outline {
    border: 1px solid $c-light-eggplant;
    background: none;
    color: $c-light-eggplant;
  }
}

.une-formation.faf {
  .card .btn, td .btn {
    background: $c-dark-pink;
  }

  .card .btn-outline, td .btn-outline {
    border: 1px solid $c-dark-pink;
    background: none;
    color: $c-dark-pink;
  }
}

.une-formation.autre {
  .card .btn, td .btn {
    background: $c-dark-indigo;
  }

  .card .btn-outline, td .btn-outline {
    border: 1px solid $c-dark-indigo;
    background: none;
    color: $c-dark-indigo;
  }
}

.une-formation.a2fm {
  .card .btn, td .btn {
    background: $c-dark-indigo;
  }

  .card .btn-outline, td .btn-outline {
    border: 1px solid $c-dark-indigo;
    background: none;
    color: $c-dark-indigo;
  }
}

.une-formation.une-formation-payante.blue {
  .card::after {
    background: $c-vivid-blue;
  }
  i {
    color: $c-vivid-blue;
    font-size: 50px;
  }
}

.une-formation.une-formation-payante.blue-dark {
  .card::after {
    background: $c-dark-indigo;
  }
  i {
    color: $c-dark-indigo;
    font-size: 50px;
  }
}

.une-formation.une-formation-payante.green {
  .card::after {
    background: $c-greenish-cyan;
  }
  i {
    color: $c-greenish-cyan;
    font-size: 50px;
  }
}

.une-formation.dpc-presentiel .card-img-top {
  //background:url('../img/dpc-non-presentiel.jpeg') no-repeat;
  background-size: cover;
  background-position: center;
}

.une-formation.faf .card-img-top {
  //background:url('../img/dpc-presentiel.jpeg') no-repeat;
  background-size: cover;
  background-position: center;
}

.une-formation .card-body {
  padding: 0;

  .badge-organisme {
    top: -12px;
    right: 12px;
    background: none;
    position: absolute !important;
    display: block;
    bottom: initial !important;
    left: initial !important;
    transform: none !important;
    text-transform: uppercase;
    padding: 4px 8px !important;
    font-size: 14px;
  }
}

.a2fm-liste-formations-tableau {
  .une-formation .card-body {
    .badge-organisme {
      top: 12px;
    }
  }
}

.une-formation {
  .card {
    border-radius: 8px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    background: white;

    text-decoration: none;
    transition: all ease 0.4s;

    &:hover {
      transform: scale(1.009);
    }

    .btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      border-radius: 10px;
    }

    &-organisme {
      height: 35px;
      position: absolute;
      right: 2px;
      bottom: 2px;
      border-radius: 10px;
    }
  }

  h3 {
    font-family: ArialRounded !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: $c-dark-indigo;
    text-transform: uppercase;
    height: 56px;
    overflow: hidden;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &-color {
    h3 {
      font-family: ArialRounded !important;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: #ffffff;
      min-height: 144px;
      text-transform: lowercase;

      span {
        display: block;
        text-transform: lowercase;

        &:first-letter {
          text-transform: uppercase !important;
        }
      }
    }

    i {
      font-size: 20px;
      color: white;
    }
  }

  &-intervenant {
    &-titre {
      color: $c-dark-indigo;
      font-weight: bold;
      font-size: 17px;
    }

    .a2fm-desc-info-picto i {
      font-size: 30px;
    }
    .card {
      &:hover {
        transform: none;
      }
    }
  }

  .a2fm-desc-info-bottom {
    border-top: 1px solid $c-pale-grey;

    i {
      font-size: 14px;
      color: $c-dark-indigo;
    }
  }

  .a2fm-desc-info-descr {
    color: $c-gris-brown;
    line-height: 1.3;
    min-height: 97px;
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;

    p {
      margin: 0;
      line-height: 1.3;
      font-size: 15px;
      font-family: 'Open Sans', sans-serif;
    }

    &-mini {
      min-height: 78px;
    }
  }

  .a2fm-desc-info-date {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $c-dark-indigo;
  }

  .a2fm-desc-info-color {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-dark-indigo;
  }

  .a2fm-desc-info-ville {
    font-family: 'Open Sans';
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $c-dark-indigo;
    display: block;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  .a2fm-desc-info-prix {
    font-family: 'Open Sans';
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $c-dark-indigo;
    display: block;
    padding-top: 3px;

    &:first-letter {
      text-transform: capitalize;
    }
  }
  .a2fm-desc-info-picto {
    text-align: center;
  }

  .a2fm-desc-info-p {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $c-gris-brown;
  }
}

.groupe-formation {
  .card {
    transform: scale(1) !important;
    border: 1px solid #615894;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 0 5px 2px rgba(0,0,0, 0.1);

    h4 {
      font-family: ArialRounded !important;
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #180c50;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      position: relative;
      padding: 0;
      width: 100%;
      transition: all ease .35s;
    }
  }

  .groupe-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    .btn-groupe {
      position: relative;
      bottom: auto;
      left: auto;
      transform: none;

      &.a2fm, &.we {
        background: $c-dark-indigo;
      }

      &.dpc {
        background: $c-light-eggplant;
      }

      &.faf {
        background: $c-dark-pink;
      }

      &.autre {
        background: $c-dark-indigo;
      }
    }

    .modal-groupe {
      position: absolute;
      top: 100%;
      width: 500px;
      height: 300px;
      background: red;
    }
  }

  .a2fm-desc-info-date {
    font-size: 20px;
  }

  .indication-groupe {
    color: black;
    font-style: italic;
    font-size: 10px;
  }
}

@media (min-width: 640px) {
  .modal-xl {
    max-width: 600px;
  }
}

@media (min-width: 840px) {
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1240px) {
  .modal-xl {
    max-width: 1200px;
  }
}

.more-filter {
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: none;

  &-btn {
    span, i {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.active {
    opacity: 1;
    height: auto;
    visibility: visible;
  }
}

.container-bg-search {
  //background: rgb(19,195,216);
  //background: -moz-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: -webkit-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);
  //transform: translateY(-1px);

  background: rgb(27, 13, 87);
  background: -moz-linear-gradient(90deg, rgba(27, 13, 87, 1) 0%, rgba(52, 28, 136, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(27, 13, 87, 1) 0%, rgba(52, 28, 136, 1) 100%);
  background: linear-gradient(90deg, rgba(27, 13, 87, 1) 0%, rgba(52, 28, 136, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b0d57", endColorstr="#341c88", GradientType=1);
  border-radius: 8px;

  @media (max-width: 575px) {
    border-radius: 0px;
  }

  input, select {
    height: 50px !important;
  }

  .btn-search-formation {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

.a2fm-search-form {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
}

.a2fm-search-form h4 {
  font-weight: 400;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1px;
  margin-bottom: 16px;
  font-size: 16px;
  text-transform: uppercase;
}

.a2fm-search-form .form-control {
  border: 1px solid rgba(0, 0, 0, 0);
}

.a2fm-search-form .form-control:focus {
  border-color: #3b608d;
  box-shadow: 0 0 0 0.2rem rgba(59, 96, 141, 0.35);
}

.a2fm-legende {
  font-size: 15px;
}

.a2fm-search-form .form-check-label {
  margin-top: 10px;
}

.a2fm-search-number-results {
  font-family: 'Open Sans' !important;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $c-gris-brown;
}

.a2fm-search-type-results {
  font-family: ArialRounded !important;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $c-dark-indigo;

  &.a2fm {
    color: $c-dark-indigo;
  }

  &.dpc {
    color: $c-light-eggplant;
  }

  &.faf {
    color: $c-dark-pink;
  }

  &.autre {
    color: $c-dark-indigo;
  }

  a[data-toggle="modal"] {
    background-color: #331c85;
    border-radius: 20px;
    color: white !important;
    padding: 5px 10px;
    font-size: 15px;
    text-align: center;
    min-width: 125px;

    i {
      margin-right: 3px;
      font-size: 16px;
    }
  }
}

.a2fm-search-type-theme {
  font-family: ArialRounded !important;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $c-dark-indigo;
}

.a2fm-formation-page {

  h3 {
    font-family: 'Open Sans';
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: $c-dark-indigo;
    text-transform: uppercase;

    @media (max-width: 575px) {
      font-size: 30px;
    }
  }

  h4 {
    font-family: 'Open Sans';
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    font-size: 16px;
    font-weight: 500;
    color: #000000;

    b {
      font-weight: 500;
    }
  }

  h5 {
    font-family: 'Open Sans';
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    font-size: 17px;
    font-weight: bold;
    color: $c-blue-turquoise;
    text-transform: uppercase;

    @media (max-width: 575px) {
      font-size: 18px;
    }
  }

  .text {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: $c-gris-brown;
    max-width: 75%;
    display: block;
    margin: 0 auto;
  }

  .a2fm-infos-titre-sub {
    color: $c-dark-pink;
    font-size: 30px;
  }
}

.a2fm-formation-content-center {
  p {
    font-family: 'Open Sans';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
  }
}

.moreless {
  font-family: 'Open Sans';
}

.a2fm-formation-content {
  &-top {
    background: #ecf7fa;
    border-radius: 8px;

    @media (max-width: 575px) {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    h4 {
      font-family: 'Open Sans' !important;
    }
    .btn-access-formation {
      width: auto;
      @media (max-width: 575px) {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
      }
    }

    &-organisme {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 45px;
      border-radius: 12px;
    }
  }
  &-bottom {
    background: #ecf7fa;
    border-radius: 8px;

    select {
      background: $c-light-eggplant;
      color: white;
      border: 0;
    }
  }
}

.a2fm-bg-white {
  background-color: white;
  border-radius: 20px;
}

.a2fm-formation-img {
  position: absolute;
  left: 10px;
  bottom: 0;
  width: 17%;
  max-width: 200px;
  z-index: 1;
}

.a2fm-infos-formation {
  background: #ecf7fa;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;

  @media (max-width: 575px) {
    box-shadow: none;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: $c-pale-grey;
  }

  &-titre {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-gris-brown;
    padding-bottom: .5rem;
    text-transform: uppercase;

    @media (max-width: 575px) {
      font-size: 15px;
    }
  }
  &-chiffre {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 10px;

    @media (max-width: 575px) {
      font-size: 16px;
    }

    &-prix {
      color: $c-dark-pink;
    }
  }

  &-lien {
    color: $c-blue-turquoise;
  }

  &-formateurs {
    &-titre {
      font-family: 'Open Sans';
      font-size: 30px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.69;
      letter-spacing: normal;
      text-align: center;
      color: $c-dark-indigo;
    }
  }

  &-public {
    max-height: 300px;
    overflow-y: scroll;
    font-size: 15px;
    font-family: 'Open Sans';
  }
}

.a2fm-infos-formation-left-titre {
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $c-gris-brown;
}

.a2fm-left-block-container-text {
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: black;

  a {
    text-decoration: none;
  }
  b {
    font-weight: bold;
    color: $c-blue-turquoise;
  }
}

.a2fm-formation-objectif {
  h3 {
    font-family: 'Open Sans';
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $c-dark-pink;
  }
  &-text {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: $c-gris-brown;
  }
}

.a2fm-etapes-container {
  h3 {
    font-size: 22px;
  }

  .a2fm-reponse-question {
    font-size: 15px;
    font-weight: 300;

    b {
      font-weight: bold;
      color: $c-dark-indigo;
    }
  }
}

.container-input-search-formations {
  background-color: white;
  border-radius: 4px;

  @media (max-width: 575px) {
    background-color: transparent;
    border-radius: 0;
  }

  &-border {
    &:after {
      content: '';
      position: absolute;
      height: 74%;
      width: 1px;
      background: #979797;
      top: 13%;
      right: 0;
      border-radius: 4px;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    outline: none;

    @media (max-width: 575px) {
      border-radius: .25rem;
    }

    &:focus {
      box-shadow: none !important;
      border: 0 !important;
    }
  }

  select {
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none !important;
      border: 0 !important;
    }

    &[name="region"] {
      height: 100% !important;
      @media (max-width: 575px) {
        height: auto !important;
        border-radius: .25rem;
      }
    }
  }

  .container-white {
    background-color: white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    @media (max-width: 575px) {
      background-color: transparent;
      border-radius: 0;
    }
  }

  i {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 26px;
    color: $c-blue-turquoise;
  }

  button[type="submit"] {
    @media (max-width: 575px) {
      background-color: $c-blue-turquoise;
    }
  }
}

.thinking-illu {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-70%, -80%);
  width: 200px;
}

.les-etapes {
  .card {
    border-color: $c-dark-indigo;
  }
  .card-header {
    border: 0;
    background: $c-dark-indigo;

    .btn {
      font-size: 17px;
      color: white;
      text-decoration: none;
      font-weight: bold;
      white-space: normal;
    }
  }
}

.a2fm-infos-public {
  border: 1px solid $c-pale-grey;
  border-radius: 8px;

  h4 {
    font-size: 24px;
    color: $c-blue-turquoise;
    font-weight: bold;
  }
}

.illu-doctor {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(20%);
}

.table-formations.dataTable {
  th {
    border: 1px solid rgba(221, 236, 239, 80%);
    &.sorting, &.sorting_asc, &.sorting_desc {
      padding-left: 22px;

      &:after {
        left: 8px;
        right: auto;
        top: 10px;
      }
    }
  }
}

#mosaique_formations_a2fm {
  color: white;

  .titre {
    color: white;
  }
  .sous-titre {
    color: white;
    font-size: 18px;
    font-family: 'Open Sans' !important;
  }

  .h3 {
    font-size: 22px;
    position: relative;
    display: flex;
    align-items: flex-end;
  }

  .h2 {
    font-size: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .h4 {
    font-size: 20px;
    position: relative;
  }

  .deco-right {
    width: 75px;
    height: 5px;
    background: #91d3ee;
    border-radius: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .deco-bottom {
    width: 75px;
    height: 5px;
    background: #91d3ee;
    border-radius: 5px;
    margin-top: 10px;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: #2f197f;
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 11px 16px;
    border-radius: 4px;
    font-weight: bold;
    z-index: 99999999999;
    box-shadow: 1px 1px 12px 0px rgba(47,25,127,0.38);

    .fa {
      font-size: 30px;
      transition: all ease .35s;
      animation: rotate ease 1s infinite;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .owl-theme {
    .owl-nav {
      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        outline: none;
        color: #180c50;
        background: white;
        padding: 4px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 38px;
        font-size: 38px;

        span {
          position: absolute;
          top: 42%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.owl-prev {
          left: -45px;
        }
        &.owl-next {
          right: -45px;
        }
      }

    }

    .owl-dots:not(.disabled) {
      display: flex;
      justify-content: center;
      margin: 20px 0;

      .owl-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: white;
        margin: 0 2px;
        border: 1px solid #27146e;

        &.active {
          background: #91d3ee;
        }
      }
    }

    &.owl-carousel-groupe {
      .owl-nav {
        button {
          &.owl-prev {
            left: -16px;
          }
          &.owl-next {
            right: -16px;
          }
        }

      }

      .owl-dots:not(.disabled) {
        margin: 0;
        .owl-dot {
          &.active {
            background: #27146e;
          }
        }
      }
    }
  }

  .modal-xl {
    top: 50%;
    transform: translate(0, -50%);
  }

  a[data-toggle="collapse"] {
    background: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    border-radius: 2px;

    &:before {
      content: '';
      position: absolute;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
      height: 10px;
      width: 30px;
      background: white;
    }

    &[aria-expanded="false"] {
      .fa-chevron-down {
        transform: rotate(-90deg);
      }
    }

    .fa {
      color: #180c50;
      font-size: 20px;
      transition: all ease .15s;
    }

    .a2fm-search-type-results {
      color: #180c50;
      text-transform: uppercase;
      display: flex !important;
      align-items: flex-end !important;

      span {
        font-size: 18px;
        margin-bottom: 3px;
        margin-left: 2px;

        b {
          font-size: 14px;
        }
      }
    }
  }

  .a2fm-liste-formations-mosaique {
  }

  .card {
    &-body {
      .new-info {
        position: absolute;
        top: 12px;
        right: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .agenda {
          color: #180c50;
          font-size: 40px;
          font-family: ArialRounded !important;
          font-weight: bold;
          line-height: 1;
          margin-top: -5px;
          pointer-events: none;

          .deco-bottom {
            background: none;
            color: #91d3ee;
            font-size: 14px;
            width: 100%;
            height: auto;
            text-align: center;
            margin: 2px auto 6px auto;
            text-transform: uppercase;
          }
        }

        .badge-organisme {
          position: relative !important;
          top: unset !important;
          bottom: unset !important;
          left: unset !important;
          right: unset !important;
          padding: 2px 11px !important;
        }
      }
    }
  }

  .container__categories {

    .checkox-button-container {

      .checkox-button, .checkox-button-item, .box-declinaison--item {
        position: relative;
        transition: all ease .35s;
        font-family: "Open Sans", sans-serif !important;

        input {
          position: absolute;
          top: 0;
          left: 0;
          outline: none;
          cursor: none;
          z-index: -999999;
          opacity: 0;
          visibility: hidden;
        }

        &:not(.box-declinaison--item) {
          &:hover {
            cursor: pointer;
          }
        }

        h4 {
          background-color: white;
          padding: 1.25rem;
          font-family: ArialRounded !important;
          font-size: 17px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #180c50;
          text-transform: uppercase;
          text-align: center;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: start;

          @media (max-width: 575px) {
            font-size: 14px;
          }

          .radio-icon {
            width: 17px;
            height: 17px;
            min-width: 17px;
            min-height: 17px;
            border-radius: 50%;
            border: 1px solid #180c50;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
          }

          .check-icon {
            width: 17px;
            height: 17px;
            min-width: 17px;
            min-height: 17px;
            border: 1px solid #180c50;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
          }
        }

        input:checked + h4 {
          background-color: #09afdd;
          color: white;
        }

        input:checked + h4 .radio-icon {
          border-color: white;
          &:after {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: white;
          }
        }

        input:checked + h4 .check-icon {
          border-color: white;
          &:after {
            content: '';
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            background: white;
          }
        }
      }

      .new-info {
        position: absolute;
        top: 14px;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .agenda {
          color: #180c50;
          font-size: 40px;
          font-family: ArialRounded !important;
          font-weight: bold;
          line-height: 1;
          margin-top: -5px;
          pointer-events: none;

          .deco-bottom {
            background: none;
            color: #91d3ee;
            font-size: 14px;
            width: 100%;
            height: auto;
            text-align: center;
            margin: 2px auto 6px auto;
            text-transform: uppercase;
          }
        }

        .badge-organisme {
          position: relative !important;
          top: unset !important;
          bottom: unset !important;
          left: unset !important;
          right: unset !important;
          padding: 2px 11px !important;
        }
      }

      .groupe-descr {
        color: #4a4a4a;
        line-height: 1.3;
        font-family: Open Sans,sans-serif;
        font-size: 15px;
        z-index: 1;
        position: relative;

        p, span {
          color: #4a4a4a!important;
          line-height: 1.3!important;
          font-family: Open Sans,sans-serif!important;
          font-size: 15px!important;
          z-index: 1;
          position: relative;
          margin-bottom: 0;
        }
      }
    }

    &--item {
      background: white;
      border: 1px solid #615894;
      //overflow: hidden;
      border-radius: 8px;
      box-shadow: 0 0 5px 2px rgba(0,0,0,0.1);
      height: 100%;

      .checkox-button-container {
        //height: 100%;
        .checkox-button, .checkox-button-item, .box-declinaison--item {
          height: 100%;

          h4 {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            position: relative;
            padding: 0;
            width: 100%;
            background: transparent;
            transition: all ease .35s;
            min-height: 37px;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #615894;
              opacity: 0;
              transition: all ease .45s;
            }

            .arrow-deco {
              width: 32px;
              height: 32px;
              min-width: 32px;
              min-height: 32px;
              border: 1px solid #615894;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          img {
            padding: 20px 0;
            transition: all ease .35s;
            z-index: 1;
            height: 120px;
            object-fit: contain;
          }

          &:not(.box-declinaison--item) {
            &:hover {
              transform: none;

              h4 {
                padding: 0 0 0 20px;

                &:before {
                  opacity: 1;
                }
              }
              img {
                transform: scale(1.03);
              }

              .arrow-deco {
                border-width: 2px;
              }
              .fond-active {
                opacity: 0.5;
              }
            }
          }

          .fond-active {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            transition: all ease .35s;
            background-color: #09afdd;
            z-index: 0;
          }

          input:checked + .fond-active {
            opacity: 1;
          }

          input:checked + .fond-active + h4 {
            padding: 0 0 0 20px;

            &:before {
              opacity: 1;
            }
          }
        }
      }

      .box-declinaison {
        &--item {
          &--info{
            position: absolute;
            top: calc(100% - 25px);
            left: 0;
            right: 0;
            z-index: 3;
            background-color: white;
            box-shadow: 0px 10px 5px 5px rgba(0, 0, 0, 0.3);
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            border: 1px solid #ededed;

            &:not(.active) {
              opacity: 0;
              visibility: hidden;
              pointer-events: none;
              z-index: -1;
            }

            .a2fm-desc-info-bottom {
              & > div {
                color: #180c50!important;
                font-size: 14px;
              }
            }

            &--title {
              color: #180c50!important;
              font-style: italic;
              font-size: 13px;
            }

            &--content {
              border-bottom: 1px solid #ededed;
            }

            .fa-times-circle {
              position: absolute;
              right: 10px;
              top: 10px;
              color: #180c50;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      [data-action="chooseOrga"] {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        z-index: 1;
      }
    }

    .nav-link {
      box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.15);
      opacity:0.5;
      transition: opacity ease .35s;
      padding: 7px 14px!important;
      border-radius: 22px!important;
      font-family: 'Open Sans', sans-serif!important;

      &.active, &:hover {
        opacity:1;
      }

      &.active {
        border: 1px solid white;
      }

      &.all {
        background: black;
      }

      &.dpc {
        background: $c-light-eggplant;
      }

      &.faf {
        background: $c-dark-pink;
      }

      &.autre {
        background: $c-dark-indigo;
      }

      &.we {
        background: $c-dark-indigo;
      }
    }
  }
  .container__themes {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -20px;
      top: 5px;
      bottom: 5px;
      width: 2px;
      background-color: white;
      border-radius: 1px;
    }
    &--item {
      h4 {
        color: white;
        font-size: 16px;
        cursor: pointer;
        font-family: 'Open Sans' !important;
      }

      input {
        width: 20px;
        cursor: pointer;
      }
    }
  }
}

.mosaique-formations {
  .menu-fixed.fixed {
    position: relative !important;
  }
}

.container-large {
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
}

.btn-organisme {
  color: white!important;
  font-family: ArialRounded !important;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &.a2fm {
    background-color: $c-dark-indigo;
  }

  &.dpc {
    background-color: $c-light-eggplant;
  }

  &.faf {
    background-color: $c-dark-pink;
  }

  &.autre {
    background-color: $c-dark-indigo;
  }
}

.a2fm-desc-info-link {
  border-top: 1px solid #ededed;
  font-size: 12px!important;
  color: $c-dark-indigo!important;

  a {
    text-decoration: underline;
    color: $c-blue-turquoise!important;
  }

  &.text-white {
    color: white!important;

    a {
      text-decoration: underline;
      color: white!important;
    }
  }
}

.text-white-force {
  color: white!important;
  font-family: 'Open Sans', sans-serif!important;

  * {
    color: white!important;
    font-family: 'Open Sans', sans-serif!important;
  }
}

.table-formated {
  @media (max-width: 767px) {
    table {
      tr {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.text {
  &-a2fm {
    color: $c-dark-indigo;
  }

  &-dpc {
    color: $c-light-eggplant;
  }

  &-faf {
    color: $c-dark-pink;
  }

  &-autre {
    color: $c-dark-indigo;
  }
}