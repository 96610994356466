.a2fm-main-nav-bg {
  //background: rgb(19,195,216);
  //background: -moz-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: -webkit-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);
  background: white;
  border-bottom: 1px solid $c-pale-grey;
}

.a2fm-main-nav .navbar-nav {
  & > li a {
    text-transform: uppercase;
    cursor:pointer;
    font-family: HelveticaNeue-Bold, HelveticaNeue;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-blue-turquoise;
    
    @media (max-width: 991px) {
      padding: 0.75rem 1rem;
    }
  }

  .dropdown-menu {
    @media (max-width: 991px) {
      background: transparent;
      margin: 0;
      padding: 0;
      margin-left: 20px;
    }

    a {
      .fa {
        color:$c-gris-brown;
        font-size: 13px;
      }
    }
  }
}
.a2fm-main-nav .navbar-nav > li .dropdown-menu a {
  color:$c-gris-brown;
}

.a2fm-main-nav-mobile {
  .navbar-light {
    .navbar-nav .nav-link {
      color: $c-blue-turquoise;
    }

    .navbar-toggler {
      border: 0;
      color: $c-blue-turquoise;
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(4, 175, 222, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    }
  }
}

.a2fm-main-header
{
  .a2fm-header-content {
    h1 {
      font-size: 50px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.03;
      letter-spacing: 3px;
      color: #ffffff;

      @media (max-width: 1140px) {
        font-size: 46px;
      }

      @media (max-width: 768px) {
        font-size: 40px;
      }

      @media (max-width: 575px) {
        font-size: 1.65rem;
        letter-spacing: 2px;
      }
    }
    h2 {
      @media (max-width: 768px) {
        font-size: 28px;
      }

      @media (max-width: 575px) {
        font-size: 22px;
      }
    }
    h3 {
      font-size: 21px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: 'Open Sans', sans-serif!important;

      @media (max-width: 575px) {
        font-size: 17px;
      }
    }
  }
}

.bloc-fixed-right {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 50;
  box-shadow: 0px 2px 8px -2px rgba(165,165,165,0.35);

  .bloc-oscar {
    background: $c-dark-indigo;
    color: white;
    border-radius: 0;
    text-align: center;
    padding: 15px;

    @media (max-width: 575px) {
      padding: 10px;
    }

    &:hover {
      .oscar-full-logo {
        transform: scale(1.05);
      }
    }

    .oscar-full-logo {
      width: 55px;
      height: 55px;
      object-fit: contain;
      transition: all ease .25s;
      
      @media (max-width: 575px) {
        width: 45px;
        height: 45px;
      }
    }
  }

  .btn_modal_newsletter {
    border-radius: 0;
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 1000;

    @media (max-width: 575px) {
      width: 65px;
      height: 65px;
    }

    &:hover {
      cursor: pointer;
    }

    button {
      width: 100%;
      height: 100%;
      border: 0;
      background-color: #1d2977;
      border-radius: 0;
      outline: none!important;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: left;
      padding: 5px 10px;
      line-height: 1.2;
      font-weight: 900;
      font-size: 13px;

      @media (max-width: 575px) {
        font-size: 11px;
      }

      &:hover {
        cursor: pointer;

        i {
          transform: scale(1.05);
        }
      }
    }

    i {
      font-size: 25px;
      color: white;
      transition: all ease .25s;
    }
  }
}

.container-bg-header {
  //background: rgb(19,195,216);
  //background: -moz-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: -webkit-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);

  /*background: rgb(27,13,87);
  background: -moz-linear-gradient(90deg, rgba(27,13,87,1) 0%, rgba(52,28,136,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(27,13,87,1) 0%, rgba(52,28,136,1) 100%);
  background: linear-gradient(90deg, rgba(27,13,87,1) 0%, rgba(52,28,136,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b0d57",endColorstr="#341c88",GradientType=1);*/

  position: relative;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  //&:after {
  //  content: '';
  //  position: absolute;
  //  top: 99.5%;
  //  left: 0;
  //  right: 0;
  //  width: 100%;
  //  height: 20px;
  //  background: rgb(19,195,216);
  //  background: -moz-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //  background: -webkit-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //  background: linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);
  //  border-bottom-right-radius: 50%;
  //  border-bottom-left-radius: 50%;
  //}

  .a2fm-main-header {
    .illu-home {
      width: 100%;
      max-width: 574px;
      min-width: 470px;
      margin: 0 auto;
      display: block;
      z-index: 0;
    }

    .a2fm-right-search {
      .a2fm-search-select {
        border-radius: 10px;
      }
      .a2fm-search-input {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: none;

        &-second {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
      .btn {
        background-color: #ffffff;
        border-radius: 0px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        color: $c-blue-turquoise;

        &.btn-blue {
          border-radius: 10px;
          padding: 13px 30px!important;

          @media (max-width: 580px) {
              width: 100%;
          }
        }

        i {
          color: $c-blue-turquoise;
          font-size: 22px;
        }
      }
    }
  }
}

.menu-fixed {
  .certif-qualiopi {
    position: absolute;
    left: 50%;
    bottom: -50px;
    background: white;
    border: none;
    outline: none!important;
    z-index: 1;
    transform: translateX(-50%);
    min-width: 100%;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: #000091;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    img {
      height: 50px;
    }
  }

  &.fixed {
    .certif-qualiopi {
      display: none!important;
    }
  }
}

#qualiopi {
  h2 {
    color: $c-dark-indigo;
  }
}
