.deco-fond-gabarit {
  position: absolute;
  right: 15%;
  top: 48%;
  transform: translateY(-50%);
  z-index: 0;
}

.content-body-equipe {
  margin-top: -80px;
  margin-bottom: 80px;
}
.container-bg-gradient-gabarit {
  background-color: #291672;
  background-size: cover;
  background-position: center;
}

@media (max-width: 580px) {
  .container-bg-gradient-gabarit:before {
    min-height: 100vh;
  }
}

.container-bg-gradient-gabarit .a2fm-page-contenu-gabarit h3, .container-bg-gradient-gabarit .a2fm-page-contenu-gabarit h4,.a2fm-page-contenu-gabarit .a2fm-formation-ariane,.a2fm-page-contenu-gabarit .a2fm-formation-ariane a {
  color: white!important;
}

.a2fm-page-contenu-gabarit h4 {
  margin-bottom: 35px!important;
}

.a2fm-page-contenu-administrable .chapeau p {
  color: white;
  line-height: 1.5;

  &:last-child {
    margin-bottom: 0!important;
  }
}

.a2fm-page-contenu-administrable-equipe .une-personne-fond {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.a2fm-page-contenu-administrable-equipe .une-personne:nth-child(4n+1) .une-personne-fond {
  min-height: 285px;
}

.a2fm-page-contenu-administrable-equipe .une-personne:nth-child(4n+2) .une-personne-fond {
  min-height: 285px;
}

.a2fm-page-contenu-administrable-equipe .une-personne:nth-child(4n+3) .une-personne-fond {
  min-height: 285px;
}

.a2fm-page-contenu-administrable-equipe .une-personne:nth-child(4n+4) .une-personne-fond {
  min-height: 285px;
}

.a2fm-page-contenu-administrable-equipe .une-personne-fond {
  padding: 15px;
  position: relative;
  overflow: scroll;
}

.a2fm-page-contenu-administrable-equipe .une-personne-fond:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d03a91;
  z-index: 0;
  opacity: 0;
}

.a2fm-page-contenu-administrable-equipe .une-personne-descr {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  z-index: 2;
}

.a2fm-page-contenu-administrable-equipe .une-personne-titre {
  color: white;
  font-weight: bold;
  position: relative;
  font-family: ArialRounded;
}

.a2fm-page-contenu-administrable-equipe .une-personne-titre:after {
  content: '';
  position: absolute;
  left: 0;
  width: 40px;
  height: 4px;
  border-radius: 10px;
  background-color: #d03a91;
  bottom: 13px;
  opacity: 0;
}

.a2fm-page-contenu-administrable-equipe .une-personne-contenu {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: height ease .5s;
  max-height: 210px;
  overflow: scroll;
}

.a2fm-page-contenu-administrable-equipe .une-personne-contenu p {
  word-break: break-all;
  color: white;
  margin: 0;
}

.a2fm-page-contenu-administrable-equipe .une-personne-fond:hover:after {
  opacity: 0.5;
}

.a2fm-page-contenu-administrable-equipe .une-personne-fond:hover .une-personne-contenu {
  height: auto;
  opacity: 1;
  visibility: visible;
}

.a2fm-page-contenu-administrable-equipe .une-personne-fond:hover .une-personne-titre {
  padding-bottom: 20px;
}

.a2fm-page-contenu-administrable-equipe .une-personne-fond:hover .une-personne-titre:after {
  opacity: 1;
}

.a2fm-page-contenu-administrable-equipe .une-personne {
  min-height: 285px;
}

@media (min-width: 1200px) {
  .a2fm-page-contenu-administrable-equipe .une-personne:nth-child(4n+1) {
    padding-top: 30px;
    margin-bottom: -20px!important;
  }

  .a2fm-page-contenu-administrable-equipe .une-personne:nth-child(4n+3) {
    padding-top: 50px;
    margin-bottom: -40px!important;
  }

  .a2fm-page-contenu-administrable-equipe .une-personne:nth-child(4n+4) {
    padding-top: 10px;
  }
}


.a2fm-page-contenu-administrable-programmes .un-programme-content {
  border-radius: 10px;
  background-color: #fbfbfb;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.3);
}

.a2fm-page-contenu-administrable-programmes .un-programme-titre {
  text-align: center;
  font-size: 24px;
  color: #6339fd;
  font-weight: bold;
  font-family: ArialRounded;
}

.a2fm-page-contenu-administrable-programmes .un-programme-contenu p, .a2fm-page-contenu-administrable-programmes .un-programme-contenu ul li {
  color: black!important;
  line-height: 1.5!important;
}

.a2fm-page-contenu-administrable-programmes .un-programme-contenu h1, .a2fm-page-contenu-administrable-programmes .un-programme-contenu h2,
.a2fm-page-contenu-administrable-programmes .un-programme-contenu h3, .a2fm-page-contenu-administrable-programmes .un-programme-contenu h4,
.a2fm-page-contenu-administrable-programmes .un-programme-contenu h5, .a2fm-page-contenu-administrable-programmes .un-programme-contenu h6 {
  font-weight: bold;
  color: black;
  font-size: 16px;
  text-transform: none;
  margin-bottom: 20px;
}

.a2fm-page-contenu-administrable-programmes {
  position: relative;
}

.a2fm-page-contenu-administrable-programmes .deco-programmes img {
  max-width: 300px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.a2fm-page-contenu-administrable-faq.a2fm-page-contenu-gabarit h3:after {
  content: '';
  position: absolute;
  left: 0;
  width: 40px;
  height: 4px;
  border-radius: 10px;
  background-color: white;
  bottom: 0;;
}

#search_faq {
  background: white;
  color: #8f8f8f;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  height: 48px;
  border-radius: 0;
  -webkit-appearance: none;
  border: 0;
  outline: none;
  padding-left: 24px;
  padding-right: 24px;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.faq-search {
  position: relative;
}

.faq-search i {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  font-size: 25px;
  color: #04afde;
}

.a2fm-page-contenu-administrable-faq .container-questions {
  border-radius: 10px;
  background-color: #fbfbfb;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.25);
}

.a2fm-page-contenu-administrable-faq .container-questions .question {
  font-size: 16px;
  color: #1e105d;
  font-weight: bold;
  font-family: ArialRounded;
  text-decoration: none;
  position: relative;
  padding-right: 25px;
}

.a2fm-page-contenu-administrable-faq .container-questions .question:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  width: 18px;
  height: 5px;
  background-color: #242b3e;
  transform: translateY(-50%);
  border-radius: 1px;
}

.a2fm-page-contenu-administrable-faq .container-questions .question:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 9px;
  height: 18px;
  width: 5px;
  background-color: #242b3e;
  transform: translate(50%,-50%);
  border-radius: 1px;
}

.a2fm-page-contenu-administrable-faq .container-questions .question[aria-expanded="true"]:after {
  opacity: 0;
}

.a2fm-page-contenu-administrable-faq .response p, .a2fm-page-contenu-administrable-faq .response ul li {
  color: #1e105d!important;
  line-height: 1.5!important;
}

.a2fm-page-contenu-administrable-faq .response a {
  color: #04afde!important;
}

.content-body-tableau {
  margin-top: -80px;
}

.content-body-tableau .dataTables_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-body-tableau table {
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.25);
  border: 0;
  font-family: "Open Sans";
}

.content-body-tableau table td, .content-body-tableau table th {
  padding: 17px 20px!important;
  border-left: 0;
  border-right: 0;
  border-color: #f3f3f3;
  color: #160d46;
  font-size: 13px;
  border-top: 0;
}
.content-body-tableau table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}

.content-body-tableau table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

.content-body-tableau table th {
  border-top: 0;
  background-color: #f7f7f7;
  text-transform: uppercase;
  font-size: 14px;
}

.content-body-tableau table th:first-of-type {
  border-top-left-radius: 10px;
}

.content-body-tableau table th:last-of-type {
  border-top-right-radius: 10px;
}

.content-body-tableau table.dataTable thead th.sorting:after, .content-body-tableau table.dataTable thead th.sorting_asc:after, .content-body-tableau table.dataTable thead th.sorting_desc:after {
  top: 18px!important;
}

.content-body-tableau .dataTables_length {
  display: none;
}

.content-body-tableau .dataTables_paginate {
  padding: 20px!important;
}


.content-body-tableau .dataTables_paginate span .paginate_button {
  padding: 2px!important;
  border-radius: 50%!important;
  color: #160d46!important;
  all: unset;
  width: 25px!important;
  height: 25px!important;
  display: inline-block!important;
  text-align: center;
  border: 0!important;
  line-height: 25px;
  margin: 0 2px;
}

.content-body-tableau .dataTables_paginate span .paginate_button.current {
  background: #160d46!important;
  color: white!important;
  font-weight: bold;
}

.content-body-tableau .dataTables_paginate span .paginate_button:hover {
  background: #160d46!important;
  color: white!important;
  font-weight: bold;
  cursor: pointer;
}

.content-body-tableau .dataTables_paginate span .paginate_button.current:hover {
  background: #160d46!important;
  color: white!important;
  font-weight: bold;
  cursor: pointer;
}

.content-body-tableau .dataTables_wrapper .dataTables_paginate > .paginate_button {
  background: transparent!important;
  color: #160d46!important;
  border: 0!important;
  display: inline;
  outline: none!important;
  box-shadow: none!important;
}

.content-body-tableau .dataTables_wrapper .dataTables_paginate > .paginate_button:after, .content-body-tableau .dataTables_wrapper .dataTables_paginate > .paginate_button:before {
  transform: translateY(-3px)!important;
}
.content-body-tableau .dataTables_wrapper .dataTables_paginate > .paginate_button:hover {
  background: transpar!important;
  color: #160d46!important;
  border: 0!important;
}

.lame--equipe{
  @media (min-width: 1200px) {
    margin-top: -85px;
  }

  &--membre{
    figure{
      max-width: 100%;
      width: 250px;
      padding-bottom: calc(100% - 20px);
      border: solid #d03a91 10px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      margin-bottom: 0;
      
      @media (max-width: 575px) {
        max-width: 100%;
        width: 250px;
        height: 250px;
        padding-bottom: 0;
      }

      img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: transform ease 0.25s;
        object-fit: cover;
        object-position: 50% 10%;
        border-radius: 50%;
      }
    }

    &:hover{
      img{
        transform: scale(1.1);
      }

      .lame--equipe--membre--titre {
        color: #d03a91;
      }
      .lame--equipe--membre--descr {
        color: #212529!important;
      }
    }

    &--titre {
      font-size: 18px;
      font-weight: bold;
      position: relative;
      font-family: ArialRounded;
      transition: color ease 0.25s;
    }

    &--descr {
      font-size: 16px;
      line-height: 1.4;
      transition: color ease 0.25s;
    }
  }
}