.container-bg-article-single {
  //background: rgb(19,195,216);
  //background: -moz-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: -webkit-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);
  //transform: translateY(-1px);
  position: relative;
}

.a2fm-article-single-bloc {
  border-radius: 8px;
  background: #ecf7fa;
}

.a2fm-article-single-ariane {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $c-gris-brown;


  a {
    color: $c-gris-brown;
    display: inline-block;
    text-transform: lowercase;

    &.link-current {
      color: black;
    }

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.a2fm-article-single-rs {

  font-size: 15px;
  color: black;
  font-family: "Open Sans";

  .a2fm-btn-rond {
    i {
      font-size: 18px;
      color: $c-blue-turquoise;
    }
  }
}


.a2fm-article-single {
  color: $c-gris-brown;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;

  h2, h1 {
    font-family: 'Open Sans';
    font-size: 42px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $c-dark-indigo;

    @media (max-width: 575px) {
      font-size: 32px;
    }
  }

  h3 {
    font-family: 'Open Sans';
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-dark-indigo;

    @media (max-width: 575px) {
      font-size: 30px;
    }
  }

  h4 {
    font-family: 'Open Sans'!important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-gris-brown;

    span {
      color: #000000;
    }
  }

  p {
    color: $c-gris-brown;
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
  }

  p b {
    color:inherit;
    font-weight:500;
  }

  .a2fm-article-single-content {
    p, li {
      font-family: 'Open Sans';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: $c-gris-brown;
    }
    p {
      text-align: justify;
    }
    h1 {
      font-size: 17px;
      text-align: left;
    }
    h2 {
      font-size: 16px;
      text-align: left;
    }
    h3, h4 {
      font-size: 15px;
      text-align: left;
    }
  }
}