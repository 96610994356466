/*
We declare all variables here as default so that they are easy to override...
*/
/* Border, shadows, ... */
/*
Variables that begin with $var- should NEVER be used directly in CSS rules.
they should only be included via the "@include var(property, var-name)"
mechanism.
*/
/* Border styles */
/* Cookie notice positioning */
/* Text colors */
/* Font Families */
/* White */
/* Differently shaded colors */
/*
Use @include var(property, varname) to include a variable, e.g.

    @include var(background-color, white1);

to create a white background.
*/
/* Spacing */
/* Breakpoints */

$f-primary : "ArialRounded";
$f-primary-book : "Arial";
$bold : 700;
$regular : 400;
$semi-bold : 600;

$c-bg-cookie : #d13a90;
$c-color-hover-cookie : black;
$c-color-cookie : white;

.klaro {
  font-family: inherit;
  font-family: var(--font-family, inherit);
  font-size: 14px;
  font-size: var(--font-size, 14px);
}

.klaro button {
  font-family: inherit;
  font-family: var(--font-family, inherit);
  font-size: 14px;
  font-size: var(--font-size, 14px);
}

.klaro.cm-as-context-notice {
  height: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
}

.klaro .cookie-modal,
.klaro .context-notice,
.klaro .cookie-notice {
  /* The switch - the box around the slider */
}

.klaro .cookie-modal .cm-switch-container,
.klaro .context-notice .cm-switch-container,
.klaro .cookie-notice .cm-switch-container {
  border-bottom-style: solid;
  border-bottom-style: var(--border-style, solid);
  border-bottom-width: 1px;
  border-bottom-width: var(--border-width, 1px);
  border-bottom-color: #c8c8c8;
  border-bottom-color: var(--light2, #c8c8c8);
  display: block;
  position: relative;
  padding: 10px;
  padding-left: 66px;
  line-height: 20px;
  vertical-align: middle;
  min-height: 40px;
}

.klaro .cookie-modal .cm-switch-container:last-child,
.klaro .context-notice .cm-switch-container:last-child,
.klaro .cookie-notice .cm-switch-container:last-child {
  border-bottom: 0;
}

.klaro .cookie-modal .cm-switch-container:first-child,
.klaro .context-notice .cm-switch-container:first-child,
.klaro .cookie-notice .cm-switch-container:first-child {
  margin-top: 0;
}

.klaro .cookie-modal .cm-switch-container p,
.klaro .context-notice .cm-switch-container p,
.klaro .cookie-notice .cm-switch-container p {
  margin-top: 0;
}

.klaro .cookie-modal .cm-switch,
.klaro .context-notice .cm-switch,
.klaro .cookie-notice .cm-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
}

.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
  opacity: 0.6;
}

.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
}

.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider::before {
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
  opacity: 0.8;
  cursor: not-allowed;
}

.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.klaro .cookie-modal .cm-list-input,
.klaro .context-notice .cm-list-input,
.klaro .cookie-notice .cm-list-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 50px;
  height: 30px;
}

.klaro .cookie-modal .cm-list-title,
.klaro .context-notice .cm-list-title,
.klaro .cookie-notice .cm-list-title {
  font-size: 0.9em;
  font-weight: 600;
}

.klaro .cookie-modal .cm-list-description,
.klaro .context-notice .cm-list-description,
.klaro .cookie-notice .cm-list-description {
  color: #7c7c7c;
  color: var(--dark3, #7c7c7c);
  font-size: 0.9em;
  padding-top: 4px;
}

.klaro .cookie-modal .cm-list-label,
.klaro .context-notice .cm-list-label,
.klaro .cookie-notice .cm-list-label {
  /* The slider */
  /* Rounded sliders */
}

.klaro .cookie-modal .cm-list-label .cm-switch,
.klaro .context-notice .cm-list-label .cm-switch,
.klaro .cookie-notice .cm-list-label .cm-switch {
  position: absolute;
  left: 0;
}

.klaro .cookie-modal .cm-list-label .slider,
.klaro .context-notice .cm-list-label .slider,
.klaro .cookie-notice .cm-list-label .slider {
  background-color: #f2f2f2;
  background-color: var(--white2, #f2f2f2);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  width: 50px;
  display: inline-block;
}

.klaro .cookie-modal .cm-list-label .slider::before,
.klaro .context-notice .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-label .slider::before {
  background-color: #e6e6e6;
  background-color: var(--white3, #e6e6e6);
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  transition: 0.4s;
}

.klaro .cookie-modal .cm-list-label .slider.round,
.klaro .context-notice .cm-list-label .slider.round,
.klaro .cookie-notice .cm-list-label .slider.round {
  border-radius: 30px;
}

.klaro .cookie-modal .cm-list-label .slider.round::before,
.klaro .context-notice .cm-list-label .slider.round::before,
.klaro .cookie-notice .cm-list-label .slider.round::before {
  border-radius: 50%;
}

.klaro .cookie-modal .cm-list-label input:focus + .slider,
.klaro .context-notice .cm-list-label input:focus + .slider,
.klaro .cookie-notice .cm-list-label input:focus + .slider {
  box-shadow-color: #48dfb2;
  box-shadow-color: var(--green3, #48dfb2);
  box-shadow: 0 0 1px var(color, green3);
}

.klaro .cookie-modal .cm-list-label input:checked + .slider::before,
.klaro .context-notice .cm-list-label input:checked + .slider::before,
.klaro .cookie-notice .cm-list-label input:checked + .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro .cookie-modal .cm-list-input:focus + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:focus + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:focus + .cm-list-label .slider {
  box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19);
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
  color: #1a936f;
  color: var(--green1, #1a936f);
  text-decoration: none;
}

.klaro .cookie-modal p,
.klaro .cookie-modal strong,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice strong,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice strong,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  color: $c-color-cookie;
  color: var(--light1, $c-color-cookie);
}

.klaro .cookie-modal p,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
  display: block;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-top: 0.7em;
}

.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal h3,
.klaro .cookie-modal h4,
.klaro .cookie-modal h5,
.klaro .cookie-modal h6,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice h3,
.klaro .context-notice h4,
.klaro .context-notice h5,
.klaro .context-notice h6,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice h3,
.klaro .cookie-notice h4,
.klaro .cookie-notice h5,
.klaro .cookie-notice h6 {
  font-family: inherit;
  font-family: var(--title-font-family, inherit);
}

.klaro .cookie-modal .cm-link,
.klaro .context-notice .cm-link,
.klaro .cookie-notice .cm-link {
  margin-right: 0.5em;
  vertical-align: middle;
}

.klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
  color: #fff;
  color: var(--button-text-color, #fff);
  background-color: #5c5c5c;
  background-color: var(--dark2, #5c5c5c);
  border-radius: 4px;
  border-radius: var(--border-radius, 4px);
  padding: 6px 10px;
  margin-right: 0.5em;
  border-style: none;
  padding: 0.4em;
  font-size: 1em;
  cursor: pointer;
}

.klaro .cookie-modal .cm-btn:disabled,
.klaro .context-notice .cm-btn:disabled,
.klaro .cookie-notice .cm-btn:disabled {
  opacity: 0.5;
}

.klaro .cookie-modal .cm-btn.cm-btn-close,
.klaro .context-notice .cm-btn.cm-btn-close,
.klaro .cookie-notice .cm-btn.cm-btn-close {
  background-color: #c8c8c8;
  background-color: var(--light2, #c8c8c8);
}

.klaro .cookie-modal .cm-btn.cm-btn-success,
.klaro .context-notice .cm-btn.cm-btn-success,
.klaro .cookie-notice .cm-btn.cm-btn-success {
  background-color: #1a936f;
  background-color: var(--green1, #1a936f);
}

.klaro .cookie-modal .cm-btn.cm-btn-success-var,
.klaro .context-notice .cm-btn.cm-btn-success-var,
.klaro .cookie-notice .cm-btn.cm-btn-success-var {
  background-color: #24cc9a;
  background-color: var(--green2, #24cc9a);
}

.klaro .cookie-modal .cm-btn.cm-btn-info,
.klaro .context-notice .cm-btn.cm-btn-info,
.klaro .cookie-notice .cm-btn.cm-btn-info {
  background-color: #2581c4;
  background-color: var(--blue1, #2581c4);
}

.klaro .context-notice {
  border-radius: 4px;
  border-radius: var(--border-radius, 4px);
  border-style: solid;
  border-style: var(--border-style, solid);
  border-width: 1px;
  border-width: var(--border-width, 1px);
  border-color: #c8c8c8;
  border-color: var(--light2, #c8c8c8);
  background-color: $c-color-cookie;
  background-color: var(--light1, $c-color-cookie);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 100%;
}

.klaro .context-notice.cm-dark {
  background-color: #333;
  background-color: var(--dark1, #333);
  border-color: #5c5c5c;
  border-color: var(--dark2, #5c5c5c);
}

.klaro .context-notice.cm-dark p {
  color: $c-color-cookie;
  color: var(--light1, $c-color-cookie);
}

.klaro .context-notice.cm-dark p a {
  color: #459cdc;
  color: var(--blue2, #459cdc);
}

.klaro .context-notice p {
  color: #333;
  color: var(--dark1, #333);
  flex-grow: 0;
  text-align: center;
  padding-top: 0;
  margin-top: 0;
}

.klaro .context-notice p a {
  color: #24cc9a;
  color: var(--green2, #24cc9a);
}

.klaro .context-notice p.cm-buttons {
  margin-top: 12px;
}

.klaro .cookie-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 9999999992;
}

.klaro .cookie-modal.cm-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  z-index: 0;
}

.klaro .cookie-modal.cm-embedded .cm-modal.cm-klaro {
  position: relative;
  -ms-transform: none;
  transform: none;
}

.klaro .cookie-modal .cm-bg {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.klaro .cookie-modal .cm-modal.cm-klaro {
  background-color: #333;
  background-color: var(--dark1, #333);
  color: $c-color-cookie;
  color: var(--light1, $c-color-cookie);
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: fixed;
  overflow: auto;
}

@media (min-width: 660px) {
  .klaro .cookie-modal .cm-modal.cm-klaro {
    border-radius: 4px;
    border-radius: var(--border-radius, 4px);
    position: relative;
    margin: 0 auto;
    max-width: 640px;
    height: auto;
    width: auto;
  }
}

.klaro .cookie-modal .cm-modal .hide {
  border-style: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.klaro .cookie-modal .cm-modal .hide svg {
  stroke: $c-color-cookie;
  stroke: var(--light1, $c-color-cookie);
}

.klaro .cookie-modal .cm-modal .cm-footer {
  border-top-color: #5c5c5c;
  border-top-color: var(--dark2, #5c5c5c);
  border-top-width: 1px;
  border-top-width: var(--border-width, 1px);
  border-top-style: solid;
  border-top-style: var(--border-style, solid);
  padding: 1em;
}

.klaro .cookie-modal .cm-modal .cm-footer-buttons {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
  font-size: 0.8em;
  padding-top: 4px;
  text-align: right;
  padding-right: 8px;
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by a {
  color: #5c5c5c;
  color: var(--dark2, #5c5c5c);
}

.klaro .cookie-modal .cm-modal .cm-header {
  border-bottom-width: 1px;
  border-bottom-width: var(--border-width, 1px);
  border-bottom-style: solid;
  border-bottom-style: var(--border-style, solid);
  border-bottom-color: #5c5c5c;
  border-bottom-color: var(--dark2, #5c5c5c);
  padding: 1em;
  padding-right: 24px;
}

.klaro .cookie-modal .cm-modal .cm-header h1 {
  margin: 0;
  font-size: 2em;
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-header h1.title {
  padding-right: 20px;
}

.klaro .cookie-modal .cm-modal .cm-body {
  padding: 1em;
}

.klaro .cookie-modal .cm-modal .cm-body ul {
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-body span {
  display: inline-block;
  width: auto;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes {
  padding: 0;
  margin: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-caret,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-caret {
  color: #a0a0a0;
  color: var(--light3, #a0a0a0);
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content {
  margin-left: -40px;
  display: none;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content.expanded,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content.expanded {
  margin-top: 10px;
  display: block;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose {
  position: relative;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 60px;
  min-height: 40px;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose:first-child {
  margin-top: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p {
  margin-top: 0;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p.purposes {
  color: #a0a0a0;
  color: var(--light3, #a0a0a0);
  font-size: 0.8em;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all {
  border-top-width: 1px;
  border-top-width: var(--border-width, 1px);
  border-top-style: solid;
  border-top-style: var(--border-style, solid);
  border-top-color: #5c5c5c;
  border-top-color: var(--dark2, #5c5c5c);
  padding-top: 1em;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-list-title {
  font-weight: 600;
}

.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-required {
  color: $c-color-cookie;
  padding-left: 0.2em;
  font-size: 0.8em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) {
  background-color: #333;
  background-color: var(--dark1, #333);
  z-index: 9999999992;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    border-radius: 4px;
    border-radius: var(--border-radius, 4px);
    position: fixed;
    position: var(--notice-position, fixed);
    right: 20px;
    right: var(--notice-right, 20px);
    left: auto;
    left: var(--notice-left, auto);
    bottom: 20px;
    bottom: var(--notice-bottom, 20px);
    top: auto;
    top: var(--notice-top, auto);
    max-width: 400px;
    max-width: var(--notice-max-width, 400px);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  }
}

@media (max-width: 1023px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) {
    border-style: none;
    border-radius: 0;
  }
}

.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded {
  position: relative;
  height: inherit;
  width: inherit;
  left: inherit;
  right: inherit;
  bottom: inherit;
  z-index: 0;
}

.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded .cn-body {
  padding-top: 0.5em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
  margin-bottom: 0;
  margin-right: 0;
  bottom: 0;
  padding: 1em;
  padding-top: 0;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p {
  margin-bottom: 0.5em;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p.cn-changes {
  text-decoration: underline;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-learn-more {
  display: inline-block;
  flex-grow: 1;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
  display: inline-block;
  margin-top: -0.5em;
}

@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
    width: 100%;
  }
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
  margin-top: 0.5em;
}

@media (max-width: 384px) {
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
    width: calc(50% - 0.5em);
  }
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
  margin-top: -0.5em;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: right;
  align-items: baseline;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok a,
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok div {
  margin-top: 0.5em;
}

.klaro .cookie-modal-notice {
  background-color: #333;
  background-color: var(--dark1, #333);
  color: $c-color-cookie;
  color: var(--light1, $c-color-cookie);
  z-index: 1001;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  max-height: 98%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: fixed;
  overflow: auto;
  padding: 1em;
  padding-top: 0.2em;
}

@media (min-width: 400px) {
  .klaro .cookie-modal-notice {
    border-radius: 4px;
    border-radius: var(--border-radius, 4px);
    position: relative;
    margin: 0 auto;
    max-width: 400px;
    height: auto;
    width: auto;
  }
}

.klaro .cookie-modal-notice .cn-ok {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.klaro .cookie-notice-hidden {
  display: none !important;
}

#klaro {
  font-family: $f-primary;

  .cookie-notice {
    top: 50%;
    bottom: inherit;
    left: 50%;
    right: inherit;
    transform: translate(-50%, -50%);
    width: 95%;
    background: $c-bg-cookie;
    max-width: 698px;
    max-height: 100vh;
    overflow-y: scroll;
  }

  .cm-list-title {
    font-size: 22px;
    font-weight: $bold;
    margin-bottom: 20px;

    strong {
    }

  }

  .cn-body {
    padding-bottom: 22px;
    padding-left: 30px;
    padding-right: 30px;
    
    @media (max-width: 575px) {
      padding-left: 23px;
      padding-right: 23px;
    }

    strong {
      display: block;
      font-size: 22px;
      font-weight: $bold;
      margin-bottom: 20px;
    }

    p {
      margin-top: 25px;
      font-size: 16px;
      font-weight: $regular;
      line-height: 1.3;
      margin-bottom: 25px;
    }

    .cn-learn-more {
      text-align: center;
      font-size: 16px;
      line-height: 1.2;
      font: normal normal bold 16px/22px $f-primary;
      padding: 13px 30px;
      border-radius: 50px;
      background: transparent;
      color: white;
      border: 1px solid white;
      margin-right: auto;
      transition: all 500ms ease;

      &:hover {
        background: white;
        color: $c-color-hover-cookie;
      }

    }

    .cn-ok {
    }
  }

  .cm-services, .cm-toggle-all {
    display: none;
  }

  .cm-btn {
    display: inline-block;
    margin-right: auto;
    margin-left: 16px;
    text-align: center;
    font-size: 16px;
    line-height: 1.2;
    font: normal normal bold 16px/22px $f-primary;
    padding: 13px 30px;
    border-radius: 50px;
    transition: all 500ms ease;

    &-danger {
      background: transparent;
      color: white;
      border: 1px solid white;
      transition: all 500ms ease;

      &:hover {
        background: white;
        color: black;
      }
    }

    &-success {
      margin-left: 16px;
      color: $c-color-hover-cookie;
      background: $c-color-cookie;
      position: relative;

      &:before {
        content: "";
        opacity: 0;
        border-radius: 50px;
        position: absolute;
        left: 50%;

        transition: all 500ms ease;
        top: 50%;
        border: 1px solid white;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
      }

      &:hover {
        background: transparent;
        color: white;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  .cm-footer {
    padding-bottom: 20px !important;
  }

  .cm-footer-buttons {
    display: flex;

    padding-left: 30px;
    padding-right: 30px;

    .cm-btn-accept {
      order: 1;
      color: white !important;
      background: transparent !important;
      position: relative;

      &:before {
        content: "";
        opacity: 1;
        border-radius: 50px;
        position: absolute;
        left: 50%;
        transition: all 500ms ease;
        top: 50%;
        border: 1px solid white;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
      }

      &:hover {
        background: white !important;
        color: black !important;
      }
    }

    .cm-btn-accept-all {
      order: 2;
    }

    .cn-decline {
      order: 3;
      margin-left: auto;
      margin-right: 10px;
    }

    .cm-btn-accept {
      margin-left: auto;
      margin-right: 0px;
    }

    .cm-btn {
      max-width: fit-content;
    }

    .cm-btn-accept-all {
      margin-right: 0;
    }

    .cm-btn-accept {
      order: 1;
      margin-left: 0;
    }

    .cn-decline {
      order: 2;
    }

    .cm-btn-accept-all {
      order: 3;
      max-width: 300px;
    }

    @media (max-width: 700px) {
      flex-flow: column;
      justify-content: center;

      .cm-btn {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 10px !important;
      }
      .cm-btn {
        &.cn-decline {
          margin-bottom: 0;
        }
      }
      .cm-btn-accept {
        order: 1;
      }
      .cm-btn-accept-all {
        order: 2;
      }
      .cn-decline {
        order: 3;
      }
    }
    @media (max-width: 600px) {
      .cm-btn {
        margin-bottom: 20px;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }


  }

  .cm-modal {
    width: 100%;
    max-width: 850px;
    background: $c-bg-cookie;


    font-family: $f-primary;

    strong, .title {
      font-weight: $bold;
      font-size: 22px;
      line-height: 1.3;
    }

    p {
      margin-top: 20px;
      font-weight: $regular;
      font-size: 16px;
      line-height: 1.3;
    }

    .cm-powered-by {
      display: none;
    }

    .cm {
      &-header {
        padding: 45px 45px 10px 45px;
        border-bottom: 0px solid transparent;
        @media (max-width: 600px) {
          padding-left: 20px;
          padding-right: 20px;
        }

      }

      &-switch {
        right: 72px;
        left: inherit;

        .slider {
          width: 73px;
          height: 37px;

          &:before {
            width: 26px;
            height: 26px;
          }
        }
      }

      &-body {
        border-bottom: 0px solid transparent;
        padding-left: 45px;
        padding-right: 45px;
        @media (max-width: 600px) {
          padding-left: 20px;
          padding-right: 20px;
        }

      }

      &-purposes {
        li {
          &:nth-last-child(2) {
            margin-top: 20px;
          }
        }

      }
      &-footer {
        padding-top: 20px;
        border-top: 0px solid transparent;
        padding-bottom: 45px;
      }

      &-purpose {
        padding-left: 0px;
        padding-right: 0px;
      }

      &-list-description {
        font-size: 16px;
        font-weight: $regular;
        color: white;
        line-height: 1.3;
      }

      &-services {

      }
    }

    .cm-caret {
      a {
        font-family: $f-primary;
        font-weight: $bold;
        color: white;
      }
    }

    .cm-content {
      .purposes {
        display: none;
      }

      .cm-switch {
      }
    }
  }

  @media (max-width: 640px) {
    .cm-body {
      p {
        font-size: 14px !important;

      }
    }
    .cn-body {
      p {
        font-size: 13px !important;
        margin-bottom: 20px;
      }

      .cn-learn-more {
        text-align: center;
        font-size: 14px;
        padding: 15px 10px;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        margin-bottom: 10px;
      }

      .cm-button {
        display: flex;
      }

      .cm-btn {
        margin-top: 0;
        text-align: center;
        font-size: 14px;
        padding: 15px 20px;
      }
    }
    .cm-modal {
      strong, .title {
        font-size: 18px;
      }


      .cn-btn {
        font-size: 14px;
        padding: 15px 10px;
      }

      p, .cm-btn {
        font-size: 14px;
      }


    }


  }

  label[for=purpose-item-advertising] {
    .cm-list-title {
      //font-size: 16px;
    }

    .cm-switch {
      //top: -10px;
    }

  }

  #purpose-item-advertising-description {
    .cm-list-description {
      margin-top: 0;
    }
  }
}

.klaro .cookie-modal .cm-list-label .slider, .klaro .context-notice .cm-list-label .slider, .klaro .cookie-notice .cm-list-label .slider {
  background: transparent;
  background-color: transparent !important;
  border: 1px solid white;
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider, .klaro .context-notice .cm-list-input:checked + .cm-list-label .slider, .klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
  background-color: white !important;
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before, .klaro .context-notice .cm-list-input:checked + .cm-list-label .slider::before, .klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
  background: #978B7F;
}

.klaro .cookie-modal .cm-list-label .slider::before, .klaro .context-notice .cm-list-label .slider::before, .klaro .cookie-notice .cm-list-label .slider::before {
  bottom: 4px !important;
}

.klaro .cookie-modal .cm-modal .hide svg {
  transform: scale(1.3);
}

.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before, .klaro .context-notice .cm-list-input:checked + .cm-list-label .slider::before, .klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
  transform: translateX(33px) !important;
}

.klaro .cookie-modal .cm-modal .hide {
  right: 10px !important;
  top: 16px !important;
}

@media (max-width: 384px) {
  .cn-learn-more {
    max-width: 220px !important;
  }
  .klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {

    display: flex !important;
    justify-content: space-between;
    //flex-flow: column;
    //justify-content: center;
    .cm-btn {
      margin-left: auto !important;
      margin-right: auto !important;
      //margin-bottom: 17px;
      &.cm-btn-success {
        margin-bottom: 0;
        order: 1;
      }
    }

  }
}

.cn-changes {
  display: none;
}

.cm-list-input {
  opacity: 1 !important;
  left: inherit !important;
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  top: 3px !important;

  &:before {
    content: "Inactif";
    position: relative;
    left: 0;
    font-family: $f-primary-book;
    font-size: 16px;
    color: $c-color-cookie;
    z-index: 100;
  }

  right: 85px !important;

  &:not(.half-checked) {
    right: 75px !important;

    &:before {
      content: "Actif";
    }
  }
}
@media (max-width: 400px) {
  .cm-list-title {
    display: inline-block;
    max-width: 170px;
  }
}

#klaro {
  .cm-btn.cn-decline, .cm-btn.cm-btn-accept {
    display: none!important;
  }

  .cm-footer-buttons {
    justify-content: flex-end;
  }
}