.container-bg-user-container, .container-bg-gradient {
  //background: rgb(19,195,216);
  //background: -moz-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: -webkit-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);
  //transform: translateY(-1px);
  position: relative;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,249,250,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,249,250,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,249,250,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f8f9fa",GradientType=1);

  background-position: 100% 100%;
  background-size: 100%;
  background-repeat: repeat;

  //&.after {
  //  &:after {
  //    content: '';
  //    position: absolute;
  //    top: 99.5%;
  //    left: 0;
  //    right: 0;
  //    width: 100%;
  //    height: 20px;
  //    background: rgb(19,195,216);
  //    background: -moz-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //    background: -webkit-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //    background: linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);
  //    border-bottom-right-radius: 50%;
  //    border-bottom-left-radius: 50%;
  //  }
  //}
}

.container-bg-blue {
  background-color: #ebeffd;
}
.a2fm-login-block {
  h2 {
    color: $c-dark-indigo;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  h3 {
    font-weight:300;
    max-width: 100%;
    font-size: 18px;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.a2fm-bordered-login-form , .a2fm-bg-grey{
  border-radius: 15px;
  background-color: #fcfcfc;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2);

  @media (max-width: 575px) {
    box-shadow: none;
    border-radius: 0;
  }

  label, label a, small {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: #6b6c6f;
  }

  input {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-gris-brown;
    padding: 12px;
    position: relative;

    &.input-picto {
      padding-left: 50px;
    }

    &::placeholder {
      color: #dfe2e6;
    }

  }

  i.first {
    position: absolute;
    left: 28px;
    bottom: 0px;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 20px;
    color: $c-blue-turquoise;
  }
}