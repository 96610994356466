.a2fm-main-footer {
  background:#fff;
  border-top: 1px solid $c-pale-grey;
}

.a2fm-main-footer {
  .a2fm-main-footer-descr {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: $c-gris-brown;
  }
  .a2fm-footer-col-contact {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $c-gris-brown;
    font-style: normal;
    line-height: 1.5;

    span {
      font-style: italic;
      line-height: normal;
    }
  }
}


.a2fm-btn-rond img {
  width:20px;
  display:inline-block;
}



.a2fm-footer-col h3 {
  font-family: 'Open Sans'!important;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $c-gris-brown;
  text-transform: uppercase;
}

.a2fm-footer-col i {
  margin-right:6px;
}


.a2fm-footer-col .tags {
  list-style-type: none;
  padding:0;
  display:flex;
  justify-content: space-between;
}

.a2fm-footer-col li  {
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.92;
  letter-spacing: normal;
  color: $c-gris-brown;

  a {
    color: $c-gris-brown;
  }
}

.a2fm-copy {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $c-gris-brown;
}

.a2fm-copy a {
  color: $c-gris-brown;
  margin:0 4px;
}


.a2fm-foot-menu {
  list-style-type: none;
  display:flex;
  justify-content: space-between;
}

.a2fm-foot-menu a.selected {
  color:white;
}

.a2fm-foot-menu a {
  text-transform: uppercase;
  font-size:15px;
  color:#8f8f8f;
  transition:0.3s all ease;
}

.a2fm-foot-menu a:hover {
  color:white;
  text-decoration: none;
}

.a2fm-main-footer {
  .certif-qualiopi {
    background: white;
    border: none;
    outline: none!important;
    z-index: 1;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-size: 14px;
    margin-left: -15px;
    
    @media (min-width: 1200px) {
      margin-top: -15px;
      margin-left: 0;
    }

    &:hover {
      cursor: pointer;
    }

    img {
      height: 90px;
    }
  }
}

.certif-action {
  color: #4a4a4a;

  span {
    color: #E1000F;
    font-weight: bold;
    text-transform: uppercase;
  }
}