body.list, body.easyadmin {

    .table-bordered:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table)>thead>tr>th,
    .table-bordered:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table)>tbody>tr>th,
    .table-bordered:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table)>tfoot>tr>th{
      border: 0;
    }

    .table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) .thead-light th {
      background: white;
      border: 0;
    }

    .table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table), .table-bordered:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) {
      border: 0;

      thead tr th {
        border-bottom: 0;

        &.sorted {
          border: 0;
        }
      }
    }

    table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) {
      thead th {
        background: white;
      }
      tbody td, tbody th, tfoot td, tfoot th {
        border-top: 0px;
        border-bottom: 1px solid #e7e7e7!important;
      }

      td a {
        text-decoration: underline;
      }
    }

    table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) {
      border:0;
    }

    .table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) tbody {
      border:0 !important;
    }

    table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) tbody td {
      border-color:white !important;
      border-top:1px solid white;
      border-bottom: solid 1px #e7e7e7!important;
      background: white!important;

      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #000000;
      font-family: 'Open Sans';
    }

    table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) tbody > tr:first-child td {
      border: 0;
      background: white;
    }

    table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) thead th {
      background:white;
    }

    .table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) thead tr th {
      border-bottom:0;
      border-top:0;
      padding:7px 0;
      text-transform: uppercase;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6d7278;
    }

    .table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) tbody tr:nth-child(odd) {
      background:white;
    }

    .table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) tbody tr:nth-child(even) {
      background:white;
    }

    .table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) thead tr th.sorted,
    .table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) thead tr th:first-child.sorted {
      border:0;
      background: white;
    }

    table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) thead th.sorted {
      a {
        color: $c-dark-indigo;
        background: transparent;
        border-radius: 0px;
        padding: 10px 8px;

        &:hover {
          i {
            color: $c-dark-indigo;
          }
        }
      }
      i {
        color: $c-dark-indigo;
      }
    }

    table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) thead th:not(.sorted) a:hover {
      background:inherit;
    }

    table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) thead th a, table thead th span {
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6d7278;
    }

    .table-responsive:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table), table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) {
      padding: 1rem;
      background: white;
      border-radius: 14px;
      margin-bottom: 1rem;

      .label, .badge {
        background: transparent!important;
        color: black!important;
        font-weight: normal;
        text-transform: none;
        font-size: 100%;

        &.label-empty {
          border: 0;
        }
      }
    }

    table:not(.fc-scrollgrid):not(.fc-col-header):not(.fc-scrollgrid-sync-table) tbody td.actions a {
      margin-right:0;
    }

    .global-actions {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      .button-action {
        width: auto;
        padding-left: 0;
        padding-top: 15px;

        @media (max-width: 580px) {
          width: 100%;

          a {
            min-width: 100%;
            text-align: center;

            span {
              margin: 0 auto;
            }
          }
        }

        .btn {
          background:$c-blue-turquoise;
          border:0px;
          border-radius:0;
          height:100%;
          display:inline-block;
          padding: 14px 20px;
          color: white;

          &:hover {
            background:$c-blue-turquoise;
          }
        }
      }
    }

}

body.easyadmin {
  font-family: 'Open Sans', sans-serif;

  a {
    color: $c-vivid-blue;
  }

  .btn {
    border-radius: 8px!important;
    line-height: 1.5;
    box-shadow: 0px 5px 8px -2px rgba(165, 165, 165, 0.5);

    &:hover {
      box-shadow: 0px 6px 8px -2px rgba(165, 165, 165, 0.8);
      opacity: 1!important;
    }

    &.btn-primary {
      i {
        color: white;
      }
    }
  }



section.content {
  padding:12px 22px;
  position: relative;

  @media (max-width: 580px) {
    padding:12px 15px;
  }
}

  .content-header {
    padding:24px 40px;
  }

.a2fm-backoffice-dashboard, h1, h2, h3, H4, h5, h6 {
  font-family: 'Open Sans', sans-serif !important;
}

.a2fm-backoffice-dashboard {
  @media (max-width: 575px) {
    .font-size-16 {
      font-size: 10px;
    }
    .box-title {
      font-size: 10px!important;
    }
  }
}

.a2fm-backoffice-dashboard .box-title {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: $c-gris-brown;
  text-transform: uppercase;
}

body.easyadmin h1.title {
  font-size: 24px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: 10px 0;
}

label {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #102a43;

}
.toggle-group {
  .toggle-on, .toggle-off {
    color: white;
  }

  .toggle-on {
    background: $c-dark-indigo!important;

    &:hover, &:focus {
      background: $c-dark-indigo!important;
    }
  }

  .toggle-off {
    background: #e02020!important;

    &:hover, &:focus {
      background: #e02020!important;
    }
  }
}
input {
  background: white;
  border-radius: 4px!important;
  border: solid 1px #bcccdc !important;
  color: black!important;
  box-shadow: none!important;
  padding: 0.375rem 0.75rem !important;

  &::placeholder{
    color: black!important;
    text-transform: lowercase;
    display: block;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &:focus {
    border: 1px solid #03afdf!important;
  }

}

textarea, select {
  border-radius: 4px!important;
  border: solid 1px #bcccdc !important;
  color: black!important;
  box-shadow: none!important;
}

select {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
  }
}

.label-vide {
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 1px;
}
/*
.checkbox {

  label {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 15px;
      height: 15px;
      background: red;
    }
  }

}
*/


#emargement-etape-selector, #check-masse-stagiaires-action, #emargement-etape-selector-formateurs, #check-masse-formateurs-action {
  border: solid 1px $c-gris-brown!important;
}

.field-checkbox, .checkbox {
  label {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: $c-gris-brown;
  }
}



.input-group .input-group-addon {
  border-radius: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border: solid 1px #bcccdc;
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-weight: bold;
}

.form-group.field-choice {
  //overflow-x: scroll;
}

  .multiselect-container.dropdown-menu > li > a:hover {
    background-color: #180c50;

    label {
      color: #fff;
    }
  }

.input-search {
  position: relative;
  width: auto!important;
  box-shadow: 0px 5px 8px -2px rgba(165, 165, 165, 0.5);
  border-radius: 10px;

  &-input {
    border-radius: 10px!important;
    border: 0!important;
    padding: 11px 16px!important;
    padding-left: 60px!important;
  }
  &-btn {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;

    .btn {
      background: transparent!important;
      color: $c-dark-indigo!important;
      padding-left: 25px;
      box-shadow: none;

      i {
        font-size: 20px;
        margin-top: 4px;
      }
    }
  }
}

.a2fm-top-bar {
  background:$c-blue-turquoise;
  color:white;
}

.main-sidebar {
  background:$c-dark-indigo;
  border-right: solid 1px #f1f1f1;
  height: 100%;
  //overflow: scroll;

  .sidebar {
    height: 100%!important;
    display: flex;
    flex-direction: column;

    .sidebar-menu {
      height: 100%;
      display: flex;
      flex-direction: column;

      /*overflow-y: scroll;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }*/
    }
  }
}

.sidebar-menu > li > a, .sidebar-menu .treeview-menu > li > a {
  background:none;
  border-left:none;
  font-family: 'Open Sans', sans-serif;
  font-weight:400;
  font-size:14px;
  color: white;
  padding-left: 30px;
  z-index: 2;
}


&.sidebar-mini.sidebar-collapse {
  .sidebar-menu > li > a, .sidebar-menu .treeview-menu > li > a {
    padding: 12px 5px 12px 15px;
  }
}

&.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span {
  box-shadow: 0 2px 8px 0 rgba(165, 165, 165, 0.5);
}

&.sidebar-mini.sidebar-collapse .sidebar-menu>.treeview:hover>a>span {
  margin-left: 0px;
  padding: 12px 5px 13px 20px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 2px 8px 0 rgba(165, 165, 165, 0.5);
  width: 220px;
}

&.sidebar-mini.sidebar-collapse .sidebar-menu>.treeview .treeview-menu {
  border-bottom-right-radius: 25px;
  width: 220px;
  z-index: 4;
  //box-shadow: 0 2px 8px 0 rgba(165, 165, 165, 0.5);
  box-shadow: 0px 5px 8px -2px rgba(165,165,165,0.5);
}

.sidebar-menu .treeview-menu > li > a {
  color: black;
}


.sidebar-mini.sidebar-collapse .main-sidebar {
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.sidebar-menu > li:hover > a,
.sidebar-menu .treeview-menu > li:hover > a,
.sidebar-menu > li.active > a, .sidebar-menu .treeview-menu > li.active > a,
.sidebar-collapse .sidebar-menu > li.active.submenu-active > a,
.sidebar-collapse .sidebar-menu > li:hover .treeview-menu > li.active > a {
  border-left:none;
  background:white!important;
  border-radius: 25px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: black!important;
}

.sidebar-menu > li.active.submenu-active > a, .sidebar-collapse .sidebar-menu > li.active.submenu-active:hover > a, .sidebar-menu > li.treeview.active > a {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 0 !important;
}

&.sidebar-mini.sidebar-collapse {
  .sidebar-menu > li.treeview.active > a, .sidebar-menu > li.treeview:hover > a, .sidebar-menu > li.active > a, .sidebar-menu > li:hover > a {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px !important;
  }
}

.sidebar-menu > li.treeview.active {
  z-index: 0;
}

&.sidebar-collapse .sidebar-menu > li.active.submenu-active > a {
  border-bottom-right-radius: 25px !important;
}

.sidebar-collapse .sidebar-menu > li:hover > a, .sidebar-collapse .sidebar-menu .treeview-menu > li:hover > a, .sidebar-menu > li.active.submenu-active > a, .sidebar-collapse .sidebar-menu > li.active.submenu-active:hover > a {
  background: white!important;
  color: black;
}

.sidebar-menu .treeview-menu > li:hover > a, .sidebar-menu .treeview-menu > li.active > a {
  text-decoration: underline!important;
  background: transparent;
  border: 0;
}

.sidebar-menu > li.submenu-active > a {
  //border-bottom-left-radius: 0px;
  //border-bottom-right-radius: 0px;
}

.sidebar-menu li>.treeview-menu {
  background: white;
  color: black;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 25px;

  a {
    text-transform: none;
  }
}

 &:not(.sidebar-collapse) {
   .sidebar-menu li>.treeview-menu {
     padding-top: 20px;
     z-index: -3;
     transform: translateY(-20px);
    margin-bottom: -20px;
   }
 }

.sidebar-menu li.header {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: white;
  padding-top: 2.5rem;
  display: inline-table;
}



.bloc-user-menu {
  color: black;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;

  .fa-user {
    font-size: 18px;
    color: $c-dark-indigo;
  }

  .user-menu-item {
    &-nom {
      color: black;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
    }
    &-role {
      color: black;
      text-decoration: none;
      font-size: 14px;
    }
  }
}

  .main-sidebar {
    padding-left: 1rem;
    padding-right: 1rem;
  }

.content-wrapper {
 min-height: 100vh;
}
@media (min-width:780px) {
  .main-sidebar, .left-side {
    width: 280px;
  }

  .content-wrapper {
    background: #f1f1f1;
  }

  .content-wrapper, .right-side, .main-footer {
    margin-left: 280px;
  }

  .content-wrapper-menu {
    //margin-left: 280px;
    //transition: all ease .25s;
    background: white;
  }


  &.sidebar-collapse {

    .main-sidebar {
      padding-left: 0rem;
      padding-right: 0rem;
    }

    .content-wrapper-menu {
      //margin-left: 50px;
    }
  }
}


.box {
  box-shadow: none;
  border: 0;
  border-radius: 14px;
  padding: 1.5rem;

  @media (max-width: 992px) {
    padding: 0.75rem;
  }

  &-header {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;

    .box-title {
      font-family:'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: $c-gris-brown;
      text-transform: uppercase;
    }
  }

  &-footer {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    border: 0;
  }


  &-body {
    border-radius: 14px;
    .label {
      background: transparent!important;
      color: black!important;
      font-size: 1em!important;
    }
  }
}

.nav-item .sidebar-toggle {
  background:transparent;
  padding:12px 18px;
  color:black!important;
  position:relative;
  display:inline-block;
  font-size:18px;
}

.sidebar-mini.sidebar-collapse .sidebar-toggle {
  width: auto;
  padding:12px 18px;
  background:transparent;
}

.lien-blue {
  color: $c-vivid-blue;
  text-decoration: underline;
}

.info-box-content {
  margin: 0;
}
.info-box-icon {
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 6px;
  line-height: 1;
  font-size: 16px;
  float: none;


  i {
    line-height: 2;
    font-size: 16px;
  }

}

.info-box {
  margin: 0;
  box-shadow: none;
  border-radius: 14px;
  padding: 1.5rem;

  @media (max-width: 992px) {
    padding: 0.75rem;
  }
}



.info-box-number {
  font-size: 30px;
  display: flex;
  align-items: center;
  font-weight: normal;
}

.info-box-line {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #6d7278;
}

.text-grey {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #74787c;
}

.a2fm-btn-blue-fonce, .a2fm-btn-blue-fonce:active, .a2fm-btn-blue-fonce:focus {
  border-radius: 4px;
  color: white;
  box-shadow: 0px 5px 8px -2px rgba(165, 165, 165, 0.5);

  &:hover {
    box-shadow: 0px 6px 8px -2px rgba(165, 165, 165, 0.8);
    opacity: 1!important;
  }
}

  .select2-container {
    font-size: 12px;
  }

.list-form-filters {
  position: absolute;
  top: 0;
  right: 10px;
  max-width: 320px;
  z-index: 99;

  .select2 {
    .select2-search {
      width: 100%;

      .select2-search__field {
        width: calc(100% - 22px)!important;
        border: 0!important;

        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        right: 22px;
      }
    }
  }
}

#list-form-filters .panel-heading {
  background:white;
  color:$c-dark-indigo;
  padding:14px 16px;
  cursor:pointer;
  border-radius: 10px;
  line-height: 1;
  box-shadow: 0px 5px 8px -2px rgba(165, 165, 165, 0.5);

  i {
    font-size: 16px;
  }

  &[aria-expanded="true"] {
    box-shadow: 0 2px 4px 0 rgba(138, 138, 138, 0.57);
  }
}

#list-form-filters .panel-heading .panel-title {
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
  color: $c-dark-indigo;
}

#list-form-filters .panel-heading .text-info small {
  color:white;
  display:none;
}

#list-form-filters .panel-heading .text-info::before {
  display:inline-block;
  font-size:16px;
  color:white!important;
  content:'\f078';
  font-family: Font Awesome\ 5 Free;
  font-weight: 900;
  font-style:normal;
}

#list-form-filters-form {
  display:block !important;
  overflow:hidden !important;
  background:white;
  color:black;
  border-radius: 14px;
  box-shadow: 0 2px 10px 0 rgba(138, 138, 138, 0.5);

  .list-form-filters-titre {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $c-dark-indigo;
  }
}

#list-form-filters-form.collapse.in {
  display:block !important;
}

#list-form-filters-form.collapse {
  display:none !important;
}

#list-form-filters-form .btn-primary {
  height:auto !important;
  padding: 10px 30px;
  display:block;
  border:0 !important;
  margin: 18px auto;
  font-size: 14px;
  background: $c-blue-turquoise;
  width: auto;
}

#list-form-filters-form .form-group {
  //margin-top:10px;
}

#list-form-filters-form .form-group .form-inline {
  flex-flow: row nowrap;
}

#list-form-filters-form .form-inline select {
  width:100%;
}

#list-form-filters-form .form-control {
  box-shadow: none!important;
  border: solid 1px #bcccdc!important;
  background-color: #ffffff;
  padding: .375rem .75rem!important;
  border-radius: 4px!important;
}

#list-form-filters-form .form-control:focus {
  border-color: #180c50;
  box-shadow: 0 0 0 0.2rem rgba(59, 96, 141, 0.35);
}


.table .actions a {
  display:inline-block;
  color:white !important;
  border: 0;
  padding: 0;
  transition:0.3s ease all;
  border-radius: 6px;
  background: $c-dark-indigo;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 2.5;
  padding: 0!important;
  box-shadow: 0px 5px 8px -2px rgba(165, 165, 165, 0.5);

  &:hover {
    box-shadow: 0px 6px 8px -2px rgba(165, 165, 165, 0.8);
    opacity: 1!important;
  }


  &.action-delete {
    background: #e02020;
  }

  &.action-show {
    background: $c-dark-indigo!important;
  }
  &.action-secondary {
    background: $c-blue-turquoise!important;
  }
}

.icon-edit-carre {
  display:inline-block;
  color:white !important;
  border: 0;
  padding: 0;
  transition:0.3s ease all;
  border-radius: 6px;
  background: $c-dark-indigo;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 2.0;
}

.nav-tabs-custom>.nav-tabs>li.active>a, .nav-tabs-custom>.nav-tabs>li>a {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-transform: uppercase;
}


.nav-tabs-custom>.nav-tabs>li {
  padding:16px 20px;
  border-top: 0px;

  @media (max-width: 580px) {
    padding: 10px;

    a {
      font-size: 12px!important;
    }
  }
}

.nav-tabs-custom>.nav-tabs>li.active {
  border-top-color: #04afde;
}

.nav-tabs-custom>.nav-tabs>li.disabled>a {
  opacity:0.5 !important;
  cursor: not-allowed;
  color:rgb(35, 35, 35) !important;
}

.tab-content .row {
  margin-right: auto;
  margin-left: auto;

  &.adresse_session {
    margin: 0 -15px;
  }
}

.nav-tabs-custom>.nav-tabs>li>a:hover {
  text-decoration:none;
}

.nav-tabs-custom>.nav-tabs>li>a,
.nav-tabs-custom>.nav-tabs>li {
  transition: 0.3s all ease;
}

.nav-tabs-custom>.nav-tabs {
  border-bottom-color:#ebebeb;
}

.collapse.in {
  display: block;
}
.nav-tabs-custom {
  box-shadow: none;
  border:0;
  padding: 1rem;
  background: white;
  border-radius: 14px;

  .field-dpc_phase, .field-fichier_joint, .field-etape_session {
    position: relative;
    padding-top: 25px!important;
    padding-bottom: 20px!important;
    border-bottom: 1px solid $c-pale-grey!important;

    .border-etape {
      border-left: 1px solid $c-dark-indigo!important;
    }

    .field-collection-item-action {
      margin: 0;
      padding: 0!important;
      position: absolute;
      right: -5px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;

      i {
        color: white;
        background: red;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        text-align: center;
        line-height: 1.6;
      }
    }
  }

  .control-label {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $c-gris-brown;
    text-transform: uppercase;
    font-size: 12px;

    &.required:after {
      color: $c-gris-brown;
    }

    &.a2fm-n-etape {
      color: $c-dark-indigo;
      position: absolute;
      top: 12px;
      left: 10px;
      background: transparent;
      border: 0;
      outline: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .form-control {
    border: solid 1px #bcccdc!important;

    &:focus {
      border: 1px solid #03afdf!important;
    }
  }

  .ui-sortable div.border-etape {
    margin-top: 10px;
  }
}

  .texte-label, .control-label {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $c-gris-brown;
    text-transform: uppercase;
    font-size: 12px;

    h2 {
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: $c-gris-brown;
      text-transform: uppercase;
      font-size: 12px;
      border: 0;
      margin: 0;
      padding: 0;
    }
  }

.nav-tabs-custom .tab-content {
  padding-top:24px;
}

.nav-tabs-custom>.nav-tabs>li.active {
  color: $c-blue-turquoise;
  border-bottom: 3px solid $c-blue-turquoise!important;
  border-top: 0px;

  a {
    color: $c-blue-turquoise;
  }
}

.help-block {
  font-style: italic;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: 1px;
  margin: 5px 0;
}

span.badge {
  background: $c-dark-indigo;
}

.generer-emargement {
  background: white;
  color: black;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 48px;
  margin-bottom: 48px;
  width: 100%;
  border-bottom: solid 1px #e7e7e7;

  #lien-generer-emargement-formateurs, #lien-generer-emargement, .btn-modif-blue {
    text-transform: lowercase;
    display: block;
    width: auto!important;
    background: $c-blue-turquoise;
    max-width: 250px;
    width: 100%;
    text-align:center;

    &:first-letter {
      text-transform: uppercase;
    }

  }
}

  .btn-modif-blue {
    text-transform: lowercase;
    display: block;
    width: auto!important;
    background: $c-blue-turquoise;
    max-width: 250px;
    width: 100%;
    text-align:center;

    &:first-letter {
      text-transform: uppercase;
    }

  }

  .stagiaires-tableau,
  .formateurs-tableau  {
    width:100%;
    min-width:820px;
    display:block;
    margin-bottom:24px;
  }

  .stagiaires-tableau-head .colonne-title,
  .formateurs-tableau-head .colonne-title {
    background: white;
    padding: 12px;
    color: $c-gris-brown;
    padding-right:24px;
    text-transform: uppercase;
    margin-right:4px;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .stagiaires-case,
  .formateurs-case {
    margin-bottom:4px;
    background: white;
    padding: 12px;
    display:flex;
    align-items:center;
    flex-wrap:wrap;

    .select2-container--bootstrap .select2-selection, .multiselect-native-select .btn-group .multiselect, select, select.form-control  {
      background-color: #f3f3f3;
      border:1px solid #f3f3f3!important;
    }

    .select2-container--bootstrap .select2-selection__clear {
      color: black;
    }

    .select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
      border-color: black transparent transparent;
    }
  }

  .formateurs-ligne, .stagiaires-ligne {
    border-bottom: solid 1px #e7e7e7;
  }

  .stagiaires-ligne {
    [id^=session_stagiaires_] > div:not(.checkbox):not(.field-checkbox) {
      align-items: flex-start;
    }

    .field-checkbox .checkbox {
      padding: 0;
    }
  }

  .stagiaires-tableau .form-group.field-checkbox,
  .formateurs-tableau .form-group.field-checkbox {
    background: white;
    padding: 12px;
    margin-bottom:4px;
    display:flex;
    align-items:center;
    flex-wrap:wrap;
  }

  #exec-masse-action {
    background: $c-blue-turquoise;
    text-transform: lowercase;
    display: block;
    width: 100%;
    max-width: 250px;
    text-align: center;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .field-group .box-header.with-border {
    background:#180c50;
    color:white;
    text-transform: uppercase;
    border-color:#180c50;
    padding: 13px 10px 12px;
    padding-left:14px;
  }

  .field-group .box-header.with-border h3.box-title {
    font-family:'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $c-gris-brown;
    text-transform: uppercase;
  }

  .field-group .box-header.with-border h3 i {
    color:white;
  }

  .box-body {
    background:white;
  }

  .field-group .box {
    border:0;
  }

  .tab-pane {
    .field-group .box-body {
      border-bottom-right-radius: 14px!important;
      border-bottom-left-radius: 14px!important;
    }
  }


  ul.pagination .page-item .page-link {
    border-radius: 8px;

    &:hover {
      background: $c-dark-indigo;
    }
  }

  .pagination.list-pagination-paginator {
    a {
      border-radius: 8px;
      text-decoration: none;
      margin: 0 2px;

      &:hover {
        background: $c-dark-indigo;
      }
    }
  }

  .sticky-wrapper.stuck {
    opacity: 0;
  }


  .form-actions.stuck .btn,
    #form-actions-row .btn {
    background: #04afde;
    border: 0px solid #04afde;
    border-radius: 0;
    height: 100%;
    display: inline-block;
    padding: .8em 1.8em !important;
    text-decoration:none !important;
    transition:0.3s ease all;
    height:38px;
    color:white !important;

    &:hover {
      background: #52b0d9;
      border: 0px solid #52b0d9;
    }

    &.action-delete {
      background: #6d7278;
      border: 0px solid #6d7278;

      &:hover {
        background: #6d7278;
        border: 0px solid #6d7278;
      }
    }
  }


  .action-show, .action-Traiter, .btn.btn-secondary.action-list {
    text-decoration:none !important;
    color: #494949 !important;
    background: #eaeaea !important;
    border: 1px solid #eaeaea !important;
    padding: .8em 1.8em !important;
    height: 38px;

    &:hover {
      background: #180c50 !important;
      border-color: #180c50 !important;
      color: white !important;
    }
  }





  #form-actions-row .btn-secondary {
    color: #494949 !important;
    background: #eaeaea !important;
    border: 1px solid #eaeaea !important;
  }

  #form-actions-row .btn-secondary:hover {
    background:#180c50 !important;
    border-color:#180c50 !important;
    color:white !important;
  }

  #form-actions-row .action-delete::before {
    display:inline;
    content:'\f2ed';
    font-family: Font Awesome\ 5 Free;
  }

  #form-actions-row .action-list::before {
    display:inline;
    content:'< ';
    font-weight:700;
  }


  .field-etape_session,
  .field-dpc_phase,
  .field-facture_suivi_gestion {
    border:0;
  }

  .field-fichier_joint, .field-dpc_phase, .field-etape_session, .field-intervenant_programme, .field-concepteur_programme, .field-facture_suivi_gestion {
    //border-left: 2px solid #dee2e6!important;
    //padding-left: 1rem;

    .first-control-label, & > .control-label:not(.a2fm-n-etape) {
      display: none;
    }
  }

  .field-collection > div > .form-group:first-child {
    border: 0;
  }

  .last-element-menu {

    & > ol {
      border-top: solid 1px #e7e7e7;
    }

    i {
      font-size: 16px;
      width: 22px;
    }
  }


  .a2fm-liste-formations-tableau {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .a2fm-liste-formations-tableau thead tr th,
  .a2fm-liste-formations-tableau thead tr th a {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6d7278;
    text-transform: uppercase;
  }

  .a2fm-liste-formations-tableau td,
  .a2fm-liste-formations-tableau td a {
    font-size: 16px;
  }


  .a2fm-liste-formations-tableau thead a {
    &.asc {
      background: $c-dark-indigo!important;
      border-radius: 8px;
      color: #FFFFFF;
      padding: 10px 8px;
      text-decoration: none;

      &::after {
        content:'\f0dd';
        font-family: Font Awesome\ 5 Free;
        font-weight: 900;
        display:inline-block;
        margin-left:4px;
      }
    }

    &.desc {
      background: $c-dark-indigo!important;
      border-radius: 8px;
      color: #FFFFFF;
      padding: 10px 8px;
      text-decoration: none;

      &::after {
        content:'\f0de';
        font-family: Font Awesome\ 5 Free;
        font-weight: 900;
        display:inline-block;
        margin-left:4px;
      }
    }
  }

  .a2fm-liste-formations-tableau-perso {
    tbody {
      tr{
        &:hover {
          td {
            background: $c-pale-grey!important;
          }
        }
      }
    }

    tr {
      td, th {
        @media (max-width: 575px) {
          padding-left: 8px!important;
        }
      }
    }

    th, td, a, span {
      @media (max-width: 575px) {
        font-size: 10px!important;
      }
    }
  }

  .alert.alert-info {
    background: #0091ff!important;
    font-size: 16px;
    border-radius: 9px;

    i {
      font-size: 30px;
    }
  }


  .nav-tabs-custom.custom-design {
    padding: 0;
    background: transparent;

    & > .nav-tabs  {
      border: 0;
      padding: 0 1.5rem;

      & > li {
        border: 0!important;
        margin: 0;
        padding: 12px 6px;

        a {
          color: white;
          font-size: 13px;
          border-radius: 4px;
          background-color: #c1c1c1;
          padding: 6px 15px;
          text-transform: none;
          display: block;
        }

        &.active {
          a {
            background-color: $c-dark-indigo
          }
        }

      }
    }

    &>.tab-content {
      background: transparent;
      padding: 0;
    }

    .box {
      padding: .5rem!important;

      .box-header {
        background: transparent;
        border: 0;

        .box-title {
          font-size: 12px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $c-gris-brown;
        }
      }
      .box-body {
        background: transparent;
      }
    }

    .label {
      text-transform: none;
      padding: 0;
    }

    .titre-blue {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.36;
      letter-spacing: normal;
      color:$c-blue-turquoise;
      border: 0;
      padding: 0;
      margin: 0;
    }

    .titre-grey {
      font-size: 12px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $c-gris-brown;
      border: 0;
      padding: 0;
      margin: 0;
    }

    .border-top {
      border-top: solid 1px #e7e7e7;
    }
  }

  #form_pageAnnuaire_date_naissance {
    padding: 0;
    border: 0!important;
    box-shadow: none;

    select {
      padding: .375rem .75rem;
      background: white;
      width: 33.33%;
    }
  }

  .main-rgpd {
    font-size: 16px;

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }


  .module-excellencis-liste {
    background:#04afde;
    color:white;
    padding-top:12px;
    padding-bottom:12px;
    margin-bottom:48px;
  }

  .a2fm-suivi-excell-titre > div[class^=col] {
    padding-left:0px;
    padding-right:0px;
  }

  .a2fm-suivi-excell-body {
    padding-top:2px;
  }

  .a2fm-suivi-excell-body > div[class^=col] {
    padding:0px;
  }

  .a2fm-suivi-excell-titre .a2fm-suivi-titre {
    background:white;
    padding:12px;
    width:100%;
    color:#6d7278;
    text-transform:uppercase;

    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .a2fm-suivi-excell-body {
    .a2fm-suivi-body {
      background:white;
      padding:12px;
      width:100%;
      text-transform:uppercase;
      height:100%;
      vertical-align: middle;
      border-bottom: solid 1px #e7e7e7;
    }

    .a2fm-suivi-etape-container{
      border-bottom: solid 1px #e7e7e7;
      padding: 10px;

      .a2fm-suivi-etape {
        background-color: #f3f3f3;

        &:first-child {
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }

        &:last-child {
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      }
    }
  }



  .a2fm-suivi-excell-body .a2fm-suivi-body span {
    display:inline-block;
  }

  .a2fm-suivi-excell-titre .a2fm-suivi-titre:first-child {
    margin-left:0;
  }

  .a2fm-suivi-excell-titre .a2fm-suivi-titre:last-child {
    margin-right:0;
  }

  .a2fm-suivi-etape {
    background:white;
    padding:12px;
    width:100%;
    text-transform:uppercase;
    vertical-align: middle;
  }

  .a2fm-suivi-etape.title {
    background:white;
  }

  .font-size-16 {
    font-size: 16px;
  }


  .field-collection > div > .form-group {
    display:flex;
    align-items: center;
    margin-bottom:0;
    margin-top:0;
    border:0;
  }

  .field-collection > div > .form-group:last-child {
    border-top:0;
  }

  .collection-empty {
    border:none !important;
  }

  .field-collection > div > .form-group > label::after {
    display:none;
  }


  .field-collection > div > .form-group > div.text-right {
    padding-right:16px;
    padding-left:16px;
  }


 /* #new-pagecontenu-form, #new-article-form, #new-video-form, #new-moduleexcellencis-form, #new-mailplanifie-form {
    .box {
      box-shadow: 0 2px 8px 0 rgba(165, 165, 165, 0.5);
    }
  }
  */
  #edit-conseilordre-form, #edit-association-form, #edit-moduleexcellencis-form, #edit-moduleoscar-form, #edit-templatemail-form, #edit-article-form, #edit-video-form, #edit-pagecontenu-form,
  #edit-specialite-form, #edit-typespecialite-form, #edit-orientation-form, #edit-groupeorientation-form, #edit-methodeprogramme-form, #edit-runexcellencis-form, #new-pagecontenu-form, #new-article-form, #new-video-form, #new-moduleexcellencis-form, #new-mailplanifie-form,
  #edit-newsletter-form, #edit-demandecontact-form, #edit-demandergpd-form, #new-demandergpd-form, #edit-paiement-form, #new-paiement-form, #new-avis-form, #edit-avis-form {
    box-shadow: none;
    border: 0;
    border-radius: 14px;
    padding: 1.5rem;
    background: #fff;

    #conseilordre_adresse, #association_adresse {
      .col-md-4 {
        padding-right: 1rem!important;
        padding-left: 1rem!important;
      }
      .col-md-8 {
        padding-right: 1rem!important;
        padding-left: 1rem!important;
      }
    }

    #form-actions-row {
      padding: 0 1.5rem;
    }
  }

  .titre-section-black {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
  }



  .field-orientation_projet, .field-public_concerne_projet, .field-groupe_programme {
    .form-group {
      padding: 0!important;
    }
  }


  input[type=checkbox] {
    //margin-right:15px;
  }
  .checkbox {
    input[type=checkbox].cache {
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      cursor: pointer;
      padding: 0;
      margin: 0;
    }
    & > label {
      position: relative;
      padding-left: 25px;

      input[type="checkbox"] {
        //opacity: 0; // hide it

        & + label {
          position: absolute;
          left: 0;
          //top: 50%;
          //transform: translateY(-50%);
          bottom: 0;
          cursor: pointer;
          padding: 0;
          margin: 0;
        }

        // Box.
        & + label:before {
          content: '';
          display: inline-block;
          vertical-align: text-top;
          width: 20px;
          height: 20px;
          background: white;
          border: 1px solid #bcccdc;
          border-radius: 4px;
        }

        // Box hover
        &:hover + label:before {
          background: $c-dark-indigo;
        }

        // Box focus
        &:focus + label:before {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        // Box checked
        &:checked + label:before {
          background: $c-dark-indigo;
        }

        // Disabled state label.
        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }

        // Disabled box.
        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }

        // Checkmark. Could be replaced with an image
        &:checked + label:after {
          content: '';
          position: absolute;
          left: 5px;
          top: 9px;
          background: white;
          width: 2px;
          height: 2px;
          box-shadow:
                  2px 0 0 white,
                  4px 0 0 white,
                  4px -2px 0 white,
                  4px -4px 0 white,
                  4px -6px 0 white,
                  4px -8px 0 white;
          transform: rotate(45deg);
        }
      }
    }
  }

  #carouselCalendar {
    position: relative;

    .carousel-control {
      position: absolute;
      top: 0;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: #e7e7e7;
      z-index: 1;
      text-align: center;
      line-height: 1.8;
      text-decoration: none;

      i{
        color: #6d7278;
      }

      &.left {
        right: 30px;
      }
      &.right {
        right: 0;
      }
    }

    .calendar_date {
      thead {
        th {
          color: $c-dark-indigo;
          opacity: 0.65;
          font-size: 16px;

          &.month {
            color: #6d7278;
            text-transform: capitalize;
          }
        }
      }
      tbody {
        td {
          border: 0!important;
          .text-grey {
            font-size: 14.5px;
            color: #6d7278;
          }
        }
      }

      .day {
        &.clicable {
            span {
              background-color: $c-dark-indigo;
              border-radius: 50%;
              color: white;
            }
        }

        &.today {
          span {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: 2px;
              right: 2px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 7px 7px 0;
              border-color: transparent #007bff transparent transparent;
            }
          }
        }
      }
    }
  }


  .nav-tabs-custom:not(.custom-design) {
    box-shadow: 0 2px 8px 0 rgba(165, 165, 165, 0.5);

    .table-responsive {
      box-shadow: none;
    }
  }
  .table-responsive {
    box-shadow: 0 2px 8px 0 rgba(165, 165, 165, 0.5);
  }



  .info-box-ombre {
    box-shadow: 0 2px 8px 0 rgba(165, 165, 165, 0.5);

    .table-responsive {
      box-shadow: none;
    }
  }
}

body {

  &.new, &.edit {
    .field-checkbox {
      .checkbox {
        padding: 0 1rem;
      }
    }
  }
  &.new-medecin, &.edit-medecin, &.edit-session {
    //.field-adresse, .field-entity {
      .col-md-12 {
        //padding-left:0;
        //padding-right:0;
      }
    //}
    
    @media (max-width: 580px) {
      .col-lg-12, .col-lg-6 {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  &.easyadmin {
    .field-datetime, .field-datetime {
      font-style: italic;
      color : #737373;
      margin: 0!important;
    }
  }

  &.show-demandergpd, &.show-paiement, &.show-formateursession, &.show-userformateursession {
    .form-horizontal {
      box-shadow: none;
      border: 0;
      border-radius: 14px;
      padding: 1.5rem;
      background: #fff;

      .form-control {
        border: solid 1px #bcccdc !important;
        background: white;
        border-radius: 4px !important;
        color: black !important;
        box-shadow: none !important;
        padding: 0.375rem 0.75rem !important;
      }
    }
  }

  &.show-formateursession, &.show-userformateursession {
    .form-horizontal {
      display: flex;
      flex-wrap: wrap;

      .col-sm-2, .col-sm-10 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }

  &.show-paiement {
    .form-horizontal {
      .field-datetime {
        margin-bottom: 1rem!important;
      }

      .col-sm-2, .col-sm-10 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }
}


.btn-deco-admin {
  font-size: 22px;
  color: $c-dark-indigo;
}

.progressbar-statut {
  overflow-x: scroll;

  &-vertical {
    flex-direction: column;
    max-width: 550px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      width: calc(100% - 25px)!important;
      margin-bottom: 5px!important;
      
      @media (max-width: 575px) {
        flex-direction: column;
        padding-left: 10px!important;
        height: auto!important;
      }

      &:last-child:after {
        display:block!important;
      }

      & > .field-radio {
        width: 33%!important;
      }

      .status-inputs {
        width: 100%;

        .form-group {
          display:flex!important;
          align-items: center;
          margin: 0 !important;
          margin-top: -5px!important;

          label {
            margin: 0 !important;
            width: 120px!important;
          }
        }
      }
    }
  }
}

.list-newsletter {
  .button-action {
    display: none!important;
  }
}

body.easyadmin .table-responsive span.badge.badge-inscrit {
  background-color: #331c85!important;
  color: white !important;
  font-size: 12px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
}

body.easyadmin .table-responsive span.badge.badge-salarie {
  background-color: #ee4869!important;
  color: white !important;
  font-size: 12px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
}

body.easyadmin .table-responsive span.badge.badge-demande {
  background-color: #d13990!important;
  color: white !important;
  font-size: 12px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
  max-width: 100%;
  white-space: normal;
}

body.easyadmin .table-responsive span.badge.badge-email {
  background-color: #00c0ef!important;
  color: white !important;
  font-size: 12px;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 1px;
  max-width: 100%;
  white-space: normal;
}

#modal-detail-run-reponses-container {
  .label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;

    &-warning {
      color: #fff!important;
      background-color: #f39c12 !important;
    }
    &-info {
      color: #fff!important;
      background-color: #00c0ef !important
    }
    &-success {
      color: #fff!important;
      background-color: #00a65a !important;
    }
    &-danger {
      color: #fff!important;
      background-color: #dd4b39 !important
    }
  }
}

.fa-circle-thin {
  font-weight: 400!important;
  &:before {
    content: "\f111";
  }
}

.modal.show {
  overflow-x: hidden;
  overflow-y: auto;
}

#load_generate {
  .loading-bar {
    display: flex;
    height: 10px;
    width: 100%;
    box-shadow: inset 0px 0px 0px 1px #C8C8CD;
    border-radius: 50px;
    overflow: hidden;
    margin-top: 10px;

    @keyframes progress-animation {
      0% {width: 0%;}
      20% {width: 10%;}
      40% {width: 30%;}
      50% {width: 60%;}
      100% {width: 100%;}
    }

    .progress-bar {
      display: flex;
      height: 100%;
      width: 100%;
      background: #2788c5;
      animation: progress-animation 10s ease-in-out;
    }
  }
  .message-progress {
    font-style: italic;
    font-size: 12px;
    margin: 2px 0;
  }
}

.droppable-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
}

.role_rgpd .action-importNewsletterAnnuaire {
  display: none!important;
}

.user-prerequis {
  position: relative;

  > i {
    font-size: 15px;
  }
  &-content {
    opacity: 0;
    visibility: hidden;

    position: absolute;
    top: 100%;
    left: 0;
    width: 330px;
    background-color: white;
    z-index: 1;
    border: 1px solid rgba(0,0,0, 0.2);
  }

  &:hover {
    cursor: pointer;
    .user-prerequis-content {
      opacity: 1;
      visibility: visible;
    }
  }
}

.menuitem-step {
  &_1 {
    cursor: auto!important;
  }
  &_2 {
    cursor: auto!important;
    .list-group-item {
      margin-left: 30px!important;
    }
  }
  &_3 {
    cursor: auto!important;
    .list-group-item {
      margin-left: 60px!important;
    }
  }
}

.label {
  font-size: 12px;
  padding: 3px 9px;
  border-radius: 5px;
}

#calendar {
  table tbody td:last-child {
    width: auto!important;
  }

  .fc-scrollgrid {
    border: 1px solid #DDD;
  }
}

body.easyadmin {
  .xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div:hover {
    color: black!important;
    box-shadow: #178fe5 0 1px 3px 0 inset!important;
  }
}

.child-flex {
  & > div {
    display: flex;
    align-items: center;

    label {
      margin-right: 5px;
    }
  }
}

.ui-widget.ui-widget-content {
  z-index: 10!important;
}