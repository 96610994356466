@import '../../fonts/helveticaneue/HelveticaNeue-Bold.ttf';
@import '../../fonts/helveticaneue/HelveticaNeue.ttf';
@import '../../fonts/arialrounded/arlrdbd.ttf';

@font-face {
  font-family: HelveticaNeue-Bold;
  src: local(HelveticaNeue-Bold),
  url(../../fonts/helveticaneue/HelveticaNeue-Bold.ttf) format("truetype"),
  url(../../fonts/helveticaneue/HelveticaNeue-Bold.woff) format('woff'),
  url(../../fonts/helveticaneue/HelveticaNeue-Bold.eot) format('eot'),
  url(../../fonts/helveticaneue/HelveticaNeue-Bold.svg) format('svg');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: HelveticaNeue;
  src: local(HelveticaNeue),
  url(../../fonts/helveticaneue/HelveticaNeue.ttf) format("truetype"),
  url(../../fonts/helveticaneue/HelveticaNeue.woff) format('woff'),
  url(../../fonts/helveticaneue/HelveticaNeue.eot) format('eot'),
  url(../../fonts/helveticaneue/HelveticaNeue.svg) format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: ArialRounded;
  src: local(arlrdbd),
  url(../../fonts/arialrounded/arlrdbd.ttf) format("truetype"),
  url(../../fonts/arialrounded/arlrdbd.woff) format('woff'),
  url(../../fonts/arialrounded/arlrdbd.eot) format('eot'),
  url(../../fonts/arialrounded/arlrdbd.svg) format('svg');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

$c-blue-turquoise : #04afde;
$c-gris-brown : #4a4a4a;
$c-light-eggplant: #04afde;
$c-dark-pink: #d13990;
$c-pale-grey: #ededed;
$c-dark-indigo: #180c50;
$c-greenish-cyan: #2df2b5;
$c-vivid-blue: #1233f9;
