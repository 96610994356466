.body-tunnel-formation {
  min-height: 100vh;

  .fixed-top {
    position: relative!important;
  }

  .alert.alert-info {
    background: #09afdc;
  }

  .tooltip {
    .arrow {
      border-bottom-color: black;

      &:before {
        display: none;
      }
    }
  }
}

.tunnel-formation {

  .a2fm-main-header-tunnel {
    color: white;
  }

  .formation-finder-progress {
    h1 {
      font-size: 12px;
      color: white;
      font-family: "Open Sans", sans-serif !important;
    }
    .progress-background {
      width: 100%;
      height: 4px;
      border-radius: 3px;
      background: white;
      z-index: 9;
      position: relative;
    }
    .progress-progression {
      position: absolute;
      left: 0;
      top: 0;
      width: 25%;
      height: 4px;
      border-radius: 3px;
      background: #09afdd;
      z-index: 10;
      transition: width 0.5s ease;
    }

    .progress-timeline {
      .point {
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        left: 0;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        font-size: 13px;
        background: #09afdd;
        border-radius: 100%;
        z-index: 10;
        transition: all ease .35s;

        &.inactive {
          opacity: 0;
          visibility: hidden;
        }
      }

      &[data-points="6"] {
        .point {
          &:nth-of-type(1) {
            left: calc(calc(100% / 6) - 2px);
          }
          &:nth-of-type(2) {
            left: calc(calc(calc(100% / 6) * 2) - 2px);
          }
          &:nth-of-type(3) {
            left: calc(calc(calc(100% / 6) * 3) - 2px);
          }
          &:nth-of-type(4) {
            left: calc(calc(calc(100% / 6) * 4) - 2px);
          }
          &:nth-of-type(5) {
            left: calc(calc(calc(100% / 6) * 5) - 2px);
          }
          &:nth-of-type(6) {
            left: calc(100% - 2px);
          }

          &.inactive {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
      &[data-points="5"] {
        .point {
          &:nth-of-type(1) {
            left: calc(calc(100% / 5) - 2px);
          }
          &:nth-of-type(2) {
            left: calc(calc(calc(100% / 5) * 2) - 2px);
          }
          &:nth-of-type(3) {
            left: calc(calc(calc(100% / 5) * 3) - 2px);
          }
          &:nth-of-type(4) {
            left: calc(calc(calc(100% / 5) * 4) - 2px);
          }
          &:nth-of-type(5) {
            left: calc(calc(calc(100% / 5) * 5) - 2px);
          }
          &:nth-of-type(6) {
            left: calc(100% - 2px);
          }

          &.inactive {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }

  .formation-finder-content {
    padding: 0;

    li {
      display: flex;
      opacity: 0;
      overflow: hidden;
      height: 0;
      visibility: hidden;
      transition: all ease .15s;

      &[data-step="4"] {
        overflow: visible!important;
      }

      &.active {
        overflow: visible;
        height: auto;
        opacity: 1;
        visibility: visible;
      }

      h2:not(.a2fm-search-type-results) {
        span {
          font-style: italic;
          font-size: 12px;
        }
      }


      &[data-step="0"] {
        .description {
          font-family: "Open Sans", sans-serif !important;
        }
      }
      &[data-step="1"] {

        .container-input-types {
          i {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            font-size: 26px;
            color: $c-blue-turquoise;
            z-index: 1;
          }

          .select2 {
            .select2-selection {
              padding: 16px 20px 16px 58px;

              .select2-selection__choice__remove {
                bottom: 0;
                border: 0;
                background-color: $c-dark-pink!important;
                color: white;
              }

              .select2-selection__choice {
                margin: 2px 4px!important;
                background-color: $c-dark-pink;
                border-color: $c-dark-pink;
              }

              .select2-selection__choice__display {
                font-size: 17px;
                color: white;
              }
            }
          }
        }

        .card-presence {
          background: white;
          border: 1px solid #615894;

          .checkox-button-container {
            height: 100%;
            .checkox-button {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              h3 {
                justify-content: start;
                display: flex;
                flex-direction: column;
                align-items: start;
                position: relative;
                padding: 0;
                margin-top: 10px;
                width: 100%;
                background: transparent;
                transition: all ease .35s;

                &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: #615894;
                  opacity: 0;
                  transition: all ease .45s;
                }

                .arrow-deco {
                  width: 32px;
                  height: 32px;
                  min-width: 32px;
                  min-height: 32px;
                  border: 1px solid #615894;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }

              img {
                width: 80%;
                padding: 20px 0;
                transition: all ease .35s;
                z-index: 1;
              }

              &:hover {
                transform: none;

                h3 {
                  padding: 0 0 0 20px;

                  &:before {
                    opacity: 1;
                  }
                }
                img {
                  transform: scale(1.03);
                }

                .arrow-deco {
                  border-width: 2px;
                }
                .fond-active {
                  opacity: 0.5;
                }
              }

              .fond-active {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                opacity: 0;
                transition: all ease .35s;
                background-color: #09afdd;
                z-index: 0;
              }

              input:checked + .fond-active {
                opacity: 1;
              }

              input:checked + .fond-active + h3 {
                padding: 0 0 0 20px;

                &:before {
                  opacity: 1;
                }
              }
            }
          }
        }

      }
      &[data-step="2"] {

        .container-carte-france {
          height: 585px;

          @media (max-width: 575px) {
            height: 500px;
            margin-bottom: -100px;
          }
        }

        .carte-tooltip-tunnel {
          background: white;
          opacity: 0.9;
          padding: 5px 8px;
          display: none;
          color: black;
          font-family: 'Open Sans';

          &.active {
            display: block;
          }
        }

        #carte-france-tunnel {

          @media (max-width: 767px) {
            transform: scale(0.8);
          }

          @media (max-width: 575px) {
            transform: scale(0.55);
            margin-top: -85px;
          }

          @media (max-width: 360px) {
            transform: scale(0.55);
          }

          path {
            fill: #D1D7DB;
            stroke: white;
            stroke-width: 2px;
            transition: all ease .3s;

            &.active {
              fill: #09afdd;
            }

            &:hover {
              cursor: pointer;
            }

          }
        }

        .img-location {
          width: 20px;
        }

      }
      &[data-step="3"] {
        .container-card-theme {
          overflow-y: scroll;
          overflow-x: hidden;
          max-height: 400px;
        }

        .card-theme {
          background: transparent;
          border: 4px solid white;

          .checkox-button-container {
            height: 100%;
            .checkox-button {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              h3 {
                justify-content: start;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                padding: 0;
                margin-top: 10px;
                width: 100%;
                background: transparent;
                transition: all ease .35s;
                text-align: left;
                color: white;

                .check-icon {
                  width: 38px;
                  height: 38px;
                  min-width: 38px;
                  min-height: 38px;
                  border: 0;
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  pointer-events: none;
                  border-radius: 50%;
                  background-color: white;
                }
              }

              &:hover {
                transform: none;

                h3 {
                  padding: 0 0 0 20px;
                  color: #180c50;
                }

                .fond-active {
                  opacity: 1;
                }

                .check-icon {
                  background-color: #615894;
                }
              }

              .fond-active {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all ease .35s;
                background-color: white;
                z-index: 0;
              }

              input:checked + .fond-active {
                opacity: 1;
              }

              input:checked + .fond-active + h3 {
                color: #180c50;
                background: transparent;

                .check-icon {
                  background-color: #615894;

                  &:after {
                    content: '✔';
                    font-size: 18px;
                    color: white;
                  }
                }
              }
            }
          }


        }
      }
      &[data-step="4"] {
        .formation-calendar {
          .pignose-calendar {
            width: 100%;
            max-width: 500px;
            font-family: 'Open Sans'!important;
            border: 0;
            border-radius: 8px;
            box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
            overflow: hidden;

            .pignose-calendar-top {
              padding: 1.6em 0;
              background-color: #09afdd;

              .pignose-calendar-top-date {
                padding: 0.5em 0;

                .pignose-calendar-top-month {
                  margin-bottom: 0;
                }
              }
            }
          }

          .pignose-calendar.pignose-calendar-blue .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sat, .pignose-calendar.pignose-calendar-blue .pignose-calendar-header .pignose-calendar-week.pignose-calendar-week-sun,
          .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sat a, .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-sun a
          {
            color: #180c50;
          }

          .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
            background-color: #09afdd;
          }

          .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-range a {
            background: #dadadd;
          }
        }

        .date-input-container {
          row-gap: 20px;
          column-gap: 20px;

          label {
            margin: 0;
            color: white;
            font-size: 22px;
            font-family: 'Open Sans';
          }

          input {
            padding: 10px 12px;
            min-width: 300px;
            border: 0;
            border-radius: 2px;
            box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
            font-family: 'Open Sans';
            font-size: 20px;
            position: relative;

            &::placeholder {
              text-transform: none;
            }

            &:before {
              content: 'Du';
              position: absolute;
              left: 0;
              top: 0;
              color: red;
            }
          }
        }

        .img-date {
          position: absolute;
          right: -5%;
          bottom: 0;
          width: 28%;
        }

        .easepick-wrapper {
          order: 2;

          @media (max-width: 480px) {
            min-width: 300px;
          }

          .day.start {
            background-color: #09afdd!important;

            &:after{
              border-left-color: #09afdd!important;
            }
          }

          .day.in-range {
            background-color: #615894!important;
          }
        }

      }
      &[data-step="5"] {
        .a2fm-header-content-title-end {
          .fa.fa-spinner {
            transition: all ease .5s;
            opacity: 0;
            display: none;

            &.active {
              animation: 1s ease infinite rotate;
              opacity: 1;
              display: block;

              @keyframes rotate {
                0% {
                  transform: rotate(0);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            }
          }
        }

        .container-formations{
          z-index: 1;
          .a2fm-formations-container-full {
            background: none;
          }
          .a2fm-search-number-results, .a2fm-search-type-theme, .a2fm-search-type-results {
            color: white;
          }

          .modal-body {
            color: #212529;
          }

          .a2fm-search-type-results a[data-toggle="modal"] {
            background-color: white;
          }

          .color-dpc {
            color: #04afde!important;
          }

          .color-faf {
            color: #d13990!important;
          }

          .color-autre {
            color: #180c50!important;
          }


          .img-result {
            position: absolute;
            bottom: 1%;
            right: 0;
            width: 23%;
            transform: translateX(50%);
            z-index: 0;
          }
        }


      }

      &:not([data-step="5"]) {
        .a2fm-header-content {
          overflow-x: hidden;
        }
      }
    }
  }

  .btn-action-step, .btn-action-end {

    &:not(.link-action-step) {
      font-family: 'Open Sans';
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;

      padding: 10px 25px !important;
      font-size: 16px;
      text-transform: uppercase;
      transition: all ease .35s;

      border: 2px solid #09afdd;
      background: white;
      color: #09afdd;

      &:not(.inactive):hover {
        background: #09afdd!important;
        color: white!important;
        border-color: #09afdd!important;
        transform: scale(1.01);
      }

      &.inactive {
        opacity: 0.5;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  .link-action-step {
    border: 0;
    background: 0;
    padding: 0;
    font-family: 'Open Sans';
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 16px;
    text-transform: uppercase;
    transition: all ease .35s;
    color: white;
    display: flex;
    align-items: center;

    &.inactive {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
      }
    }

    .arrow-deco {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      border: 1px solid white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:not(.inactive) {
      &:hover {
        .arrow-deco {
          border: 2px solid white!important;
          font-weight: bold;
        }
      }
    }
  }

  .checkox-button-container {

    .checkox-button {
      position: relative;
      cursor: pointer;
      transition: all ease .35s;
      font-family: "Open Sans", sans-serif !important;

      input {
        position:absolute;
        top: 0;
        left: 0;
        outline: none;
        cursor: none;
        z-index: -999999;
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        cursor: pointer;
      }

      h3 {
        background-color:white;
        padding: 1.25rem;
        font-family: ArialRounded !important;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #180c50;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: start;

        .radio-icon {
          width: 17px;
          height: 17px;
          min-width: 17px;
          min-height: 17px;
          border-radius: 50%;
          border: 1px solid #180c50;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
        }

        .check-icon {
          width: 17px;
          height: 17px;
          min-width: 17px;
          min-height: 17px;
          border: 1px solid #180c50;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
        }
      }

      input:checked + h3 {
        background-color: #09afdd;
        color: white;
      }

      input:checked + h3 .radio-icon {
        border-color: white;
        &:after {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: white;
        }
      }

      input:checked + h3 .check-icon {
        border-color: white;
        &:after {
          content: '';
          width: 10px;
          height: 10px;
          min-width: 10px;
          min-height: 10px;
          background: white;
        }
      }
    }

    &.all-regions {
      .checkox-button {
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;

          .check-icon {
            width: 17px;
            height: 17px;
            min-width: 17px;
            min-height: 17px;
            border: 1px solid white;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
          }
        }

        &:hover {
          transform: none;
        }

        input:checked + div .check-icon {

          &:after {
            content: '';
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            background: white;
          }
        }
      }
    }
  }




}