.a2fm-formations-container-full {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(238,241,254,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(238,241,254,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(238,241,254,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#eef1fe",GradientType=1);

  background-position: 100% 100%;
  background-size: cover;
  background-repeat: repeat;
}
.a2fm-formations-payantes-container-full {
  background: #ebeffd;
}
.coin {
  position: absolute;
  left: 0;
  bottom: 99.99%;
  width: 22%;
}
.a2fm-formations-content, .a2fm-actualites-container {
  h2 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 3px;
    text-align: center;
    color: $c-dark-pink;
    text-transform: uppercase;
    
    @media (max-width: 575px) {
      font-size: 28px;
      letter-spacing: 2px;
    }
  }

  h3 {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: $c-gris-brown;
    max-width: 68%;
    margin: 0 auto;

    @media (max-width: 575px) {
      max-width: 90%;
    }
  }
}

.a2fm-actualites-container {
  h2 {
    color: $c-dark-pink;
    text-transform: uppercase;
  }
}

.a2fm-formations-content-partenaires {
  h2 {
    color: $c-dark-pink;
    text-transform: uppercase;
  }
}

//actualites

.une-actualite .card,
.une-actualite .box-shadow {
  border-radius: 8px;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
  border:0;
}

.une-actualite {
  .card-img-top {
    height: 165px;
    width: 100%;
    border-radius: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  h3 {
    font-family: ArialRounded!important;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-dark-indigo;
    text-transform: uppercase;
    text-align: left;
    max-width: 100%;
    height: 44px;
  }
  .card-text {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: $c-gris-brown;
    height: 72px;
    overflow: hidden;
  }

  .a2fm-info-article {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-gris-brown;
  }

  .btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.une-actualite i.date {
  color:#c31880;
}

.une-actualite i.localisation {
  color:#5e7db9;
}

.une-actualite i.type-formation {
  color:#ee8d38;
}

.une-actualite .a2fm-infos-formation {
  padding:10px 0;
}

.une-actualite .a2fm-infos-formation::after {
  width: 48px;
  height: 2px;
  display: inline-block;
  background: #ababab;
  margin-top: 20px;
  content:'';
}

.une-actualite .btn-group {
  margin-top:10px;
  margin-bottom:24px;
}



.a2fm-auteur-publication li {
  display:inline-block;
  text-transform: uppercase;
  color:#868686;
  font-size:14px;
}

.a2fm-auteur-publication li i {
  color:#04afde;
  font-size:14px;
  margin-left:15px;
}

.carousel-multi-item {
  .btn-floating {
    position: absolute;
    top: 50%;

    i {
      font-size: 25px;
      color: black;
    }

    &[data-slide="prev"] {
      left: 10px;
      z-index: 1000;
    }

    &[data-slide="next"] {
      right: 10px;
      z-index: 1000;
    }

    @media (max-width: 1024px) {
      top: 100%;

      i {
        font-size: 28px;
      }

      &[data-slide="prev"] {
        left: 45%;
      }

      &[data-slide="next"] {
        right: 45%;
      }
    }
  }
}

.carousel-indicators {
  bottom: -25px;
  li {
    width: 13px;
    height: 13px;
    min-width: 13px;
    min-height: 13px;
    border-radius: 50%;
    background-color: $c-pale-grey;

    &.active {
      background-color: $c-dark-indigo;
    }
  }
}

//video

.a2fm-message-block h3 {
  font-family: 'Open Sans'!important;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: $c-pale-grey;

  & > p {
    font-size: 32px;
  }
}

.a2fm-message-block {
  background: rgb(19,195,216);
  background: -moz-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  background: linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);
  color:white;

}

.logo-oscar-blanc {
  position: absolute;
  bottom: 15%;
  right: 5%;
  //transform: translate(-80%, 25%);
}

.logo-oscar-blue {
  position: absolute;
  top: 0;
  left: 0;
}

.a2fm-attestation-container-full {
  h2 {
    font-family: ArialRounded!important;
    font-size: 29px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-align: center;
    color: $c-dark-indigo;
    text-transform: uppercase;
    letter-spacing: 3px;

    @media (max-width: 575px) {
      font-size: 1.65rem;
      letter-spacing: 2px;
    }
  }
}


.container-carte-france {
  height: 585px;

  @media (max-width: 575px) {
    height: 500px;
  }
}
#carte-france {
  transform: scale(0.7);

  @media (max-width: 575px) {
    transform: scale(0.6);
    margin-top: -85px;
  }

  @media (max-width: 360px) {
    transform: scale(0.55);
  }

  path {
    fill: #D1D7DB;
    stroke: white;
    stroke-width: 2px;
    transition: all ease .3s;

    &.active {
      fill: #74358d;

      &:hover {
        cursor: pointer;
        fill: #d13990;
      }
    }

  }
}

.carte-tooltip {
  background: white;
  opacity: 0.9;
  padding: 5px 8px;
  display: none;

  &.active {
    display: block;
  }
}

.deco-carte {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.deco-illu {
  position: absolute;
  bottom: -85px;
  left: 0px;
  width: 400px;
}


body:not(.easyadmin) {

  .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
    color: $c-blue-turquoise!important;
  }

  .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_label>.xdsoft_select>div>.xdsoft_option.xdsoft_current {
    background: $c-blue-turquoise!important;
    color: #fff !important;
  }

  .xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div:hover, .xdsoft_datetimepicker .xdsoft_label>.xdsoft_select>div>.xdsoft_option:hover {
    background: $c-dark-indigo!important;
    color: #fff !important;
  }
}

#carouselVideoHome {

  video, iframe {
    width: 100%;
    border-radius: 10px;
  }


  .carousel-control-next {
    right: -15%;
    color: black;

    @media (max-width: 580px) {
      right: -5%;
      top: 350px;
    }

    &-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E")
    }
  }
  .carousel-control-prev {
    left: -15%;
    color: black;

    @media (max-width: 580px) {
      left: -5%;
      top: 350px;
    }

    &-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E")
    }
  }
}

.a2fm-newsletter-container-full {
  background-color: #ebeffd;
  margin-bottom: -8px;

  h2 {
    font-family: ArialRounded !important;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    color: #180c50;
  }

  h3 {
    font-family: "Open Sans", sans-serif !important;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #d13990;
  }

  .form-check-label {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: #6b6c6f;

    .obligatoire {
      color: #ff5c6c;
      content: 'obligatoire';
      font-size: 8px;
      font-weight: 500;
      vertical-align: top;
    }
  }
}

body:not(.easyadmin) {
  .checkbox {
    input[type=checkbox].cache {
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      cursor: pointer;
      padding: 0;
      margin: 0;
    }
    & > label {
      position: relative;
      padding-left: 28px;

      input[type="checkbox"] {

        & + label {
          position: absolute;
          left: 0;
          top: -1px;
          cursor: pointer;
          padding: 0;
          margin: 0;
        }

        // Box.
        & + label:before {
          content: '';
          display: inline-block;
          vertical-align: text-top;
          width: 20px;
          height: 20px;
          background: white;
          border: 1px solid #bcccdc;
          border-radius: 4px;
        }

        // Box hover
        &:hover + label:before {
          background: $c-dark-indigo;
        }

        // Box focus
        &:focus + label:before {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        // Box checked
        &:checked + label:before {
          background: $c-dark-indigo;
        }

        // Disabled state label.
        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }

        // Disabled box.
        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }

        // Checkmark. Could be replaced with an image
        &:checked + label:after {
          content: '';
          position: absolute;
          left: 5px;
          top: 9px;
          background: white;
          width: 2px;
          height: 2px;
          box-shadow:
                  2px 0 0 white,
                  4px 0 0 white,
                  4px -2px 0 white,
                  4px -4px 0 white,
                  4px -6px 0 white,
                  4px -8px 0 white;
          transform: rotate(45deg);
        }
      }
    }
  }
}

#newsletter {
  .modal-header, .modal-body, .modal-footer {
    padding: 1.6rem;
  }
}

.a2fm-avis-container-full {
  background: #ebeffd;

  .avis-item {
    border-radius: 8px;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
    background: white;
    text-decoration: none;
    transition: all ease 0.4s;

    &-left {
      &-number {
        font-size: 45px;
        font-family: ArialRounded !important;
        padding: 2px 10px;
        background: #180c50;
        color: white;
        border-radius: 10px;

        span {
          font-size: 22px;
        }
        
        @media (min-width: 768px) {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-25%, -50%);
        }
      }
    }

    &-right {
      &-text {
        text-align: left;

        p {
          line-height: 1.3;
          margin-bottom: 0;
        }

        * {
          font-family: ArialRounded !important;
        }
      }

      &-date {
        text-align: right;
        font-style: italic;
        color: #4a4a4a;
        font-size: 14px;
      }
    }
  }
}

.avis-fixed {
  padding: 2px 10px;
  background: #180c50;
  border-radius: 10px;

  &-titre {
    color: white;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
  }
  &-number {
    font-size: 28px;
    font-family: ArialRounded !important;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
    color: white;
    text-align: right;


    span {
      font-size: 18px;
    }
  }
}

.owl-carousel-categories {
  .owl-nav {
    @media (max-width: 991px) {
      display: none;
    }

    button {
      outline:none;

      i {
        font-size: 25px;
        color: black;
      }

      &.owl-prev {
        position: absolute;
        left: -26px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.owl-next {
        position: absolute;
        right: -26px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.owl-carousel {
  .owl-dots {
    display: flex;
    justify-content: center;

    @media (min-width: 992px) {
      display: none;
    }

    .owl-dot {
      width: 13px;
      height: 13px;
      min-width: 13px;
      min-height: 13px;
      border-radius: 50%;
      background-color: #ededed;
      margin: 0 8px;
      outline: none;

      &.active {
        background-color: #180c50;
      }
    }
  }
}