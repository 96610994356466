.container-bg-main {
  //background: rgb(19,195,216);
  //background: -moz-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: -webkit-linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //background: linear-gradient(90deg, rgba(19,195,216,1) 0%, rgba(2,175,222,1) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);
  //transform: translateY(-1px);

  /*background: rgb(27,13,87);
  background: -moz-linear-gradient(90deg, rgba(27,13,87,1) 0%, rgba(52,28,136,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(27,13,87,1) 0%, rgba(52,28,136,1) 100%);
  background: linear-gradient(90deg, rgba(27,13,87,1) 0%, rgba(52,28,136,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b0d57",endColorstr="#341c88",GradientType=1);*/

  position: relative;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  //&::after {
  //  content: '';
  //  position: absolute;
  //  top: 99.5%;
  //  left: 0;
  //  right: 0;
  //  width: 100%;
  //  height: 20px;
  //  background: #13c3d8;
  //  background: -moz-linear-gradient(90deg, #13c3d8 0%, #02afde 100%);
  //  background: -webkit-linear-gradient(90deg, #13c3d8 0%, #02afde 100%);
  //  background: linear-gradient(90deg, #13c3d8 0%, #02afde 100%);
  //  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#13c3d8",endColorstr="#02afde",GradientType=1);
  //  border-bottom-right-radius: 50%;
  //  border-bottom-left-radius: 50%;
  //}
}

.a2fm-main-content p {
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: justify;
  color: #ffffff;
}

.a2fm-main-content p b {
  color:inherit;
  font-weight:500;
}

.a2fm-main-content {
  h3 {
    font-family: 'Open Sans';
    font-size: 37px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: 575px) {
      font-size: 25px;
    }
  }

  h4 {
    font-family: 'Open Sans'!important;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}


.a2fm-presentation-img {
  height:100%;
  //background:url('../img/presentation-image.png') no-repeat;
  background-size:contain;
  background-position: center;
}

.a2fm-image-bulle {
  border-radius:50%;
  height:128px;
  width:128px;
  background-size:cover;
  background-position: center;
  display:inline-block;
}

.a2fm-main-content .presentation-photo-president {
  text-align: center;
  margin-top:42px;
}

.group-image {
  width: 100%;
}

.container-img-partenaires {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 190px;
}

.a2fm-gris-block p,
.a2fm-gris-block ul {
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: justify;
  color: #000000;
}

.a2fm-gris-block p b {
  font-weight:500;
}

.a2fm-gris-block ul {
  padding-left:16px;
}

.a2fm-paragraphe-titre {
  font-family: 'Open Sans'!important;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: justify;
  color: #000000;
  font-weight: bold;

  @media (max-width: 575px) {
    font-size: 22px;
  }
}

.a2fm-paragraphe-texte {
  font-family: 'Open Sans'!important;
}

.a2fm-paragraphe-titre span {
  font-size:16px;
}

.container-bon-savoir {
  h2 {
    font-family: 'Open Sans';
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $c-dark-indigo;
    text-transform: uppercase;
  }

  h3 {
    font-family: 'Open Sans';
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-dark-pink;
    text-transform: uppercase;
  }

  p {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #000000;
  }
}




.oval-illu {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-15%);
  z-index: -1;
  width: 55%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.illu-presentation {
  width: 100%;
  max-width: 574px;
  min-width: 470px;
  margin: 0 auto;
  display: block;
  z-index: 0;

  @media (min-width: 992px) {
    transform: translateY(45%);
  }
}

.illu-attestation {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  display: block;
  z-index: 0;

  @media (min-width: 992px) {
    transform: translateY(45%);
  }
}


.a2fm-attestation-etapes {
  &-titre {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: justify;
    color: $c-dark-indigo;
  }
  &-chiffre {
    font-size: 72px;
    font-weight: bold;
    color: $c-dark-pink;
    line-height: 1;
  }
}

.attestation-etape-arrow-2 {
  position: absolute;
  top: -15%;
  left: -32%;
}

.attestation-etape-arrow-3 {
  position: absolute;
  top: -55%;
  right: -15%;
  transform: rotateY(180deg) rotateZ(15deg);
  z-index: -1;
}

.attestation-etape-arrow-4 {
  position: absolute;
  top: -20%;
  left: -28%;
}