.container-contenu {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 450px;
    z-index: -2;
    /*background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(215,242,250,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(215,242,250,1) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(215,242,250,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#d7f2fa",GradientType=1);*/

    background-image: url('../../img/rectangle-blanc.svg');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  &.comite-scientifique, &.redacteurs {
    &:after {
      height: 28%;

      @media (max-width: 768px) {
        height: 50%;
      }
    }
  }
  ul:not(.classic-list) {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 0;

    li {
      width: 46%;
      font-size: 22px;
      color: $c-dark-pink;
      font-weight: bold;
      background: white;
      border-radius: 8px;
      padding: 1.5rem;
      margin: .5rem;
      box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
      display: flex;
      align-items: center;
      min-height: 102px;

      @media (max-width: 780px) {
        width: 100%;
      }
    }
  }

  table {
    width: 100%;
    margin: 2.5rem 0;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  }
}

.a2fm-mentions-legales {
  color:$c-gris-brown;
  font-weight:300;
  font-family: 'Open Sans';

  h2 {
    font-size:20px;
    font-family: 'Open Sans';
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-gris-brown;
  }

  p, li {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $c-gris-brown;
  }

  p {
    text-align: justify;
  }

  p b {
    color:inherit;
    font-weight:500;
  }

  h3 {
    font-family: 'Open Sans';
    font-size: 34px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $c-dark-indigo;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: 575px) {
      font-size: 26px;
    }
  }

  h4 {
    font-family: 'Open Sans'!important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:$c-dark-pink;
    margin-bottom: 1.5rem
  }
}