/* assets/css/app.scss */
@import "./utils/variables";
@import "./utils/klaro";

html {
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
  font-family: Arial;
}

.c-blue-turquoise {
  color:#09afdd;
}

.c-pink {
  color:#d13a90;
}

.collapse.in {
  display: block;
}

.container-lg {
  max-width: 1440px;
}

.menu-fixed {
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100000;

    box-shadow: 0px 2px 8px -2px rgba(165,165,165,0.5);

    .logo-menu {
      height: 55px;
    }
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance:none;
}

.a2fm-search-select-container::before {
  content:'\f107';
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-weight: 900;
  font-family: Font Awesome\ 5 Free;
  font-size:20px;
  display:inline-block;
  position:absolute;
  z-index:1000;
  color:$c-blue-turquoise;
  right:13px;
  top:calc(50% - 13px);
}

.btn {
  border:0;
  border-radius: 7px;
  font-weight:400;
  font-size:12px;
  transition: opacity ease 0.4s;
}

.btn.focus, .btn:focus {
  box-shadow:none !important;
}

.btn.btn-primary {
  background:#180c50;
  transition:0.3s all ease;
}

.btn.btn-primary:hover {
  background:#180c50;
}

.btn.btn-blue, .btn.btn-blue.active {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 30px!important;
  background: $c-dark-indigo;

  &.btn-blue-thin {
    padding: 10px 30px!important;
  }
  &.btn-blue-little {
    font-size: 14px;
  }

  &.btn-turquoise {
    background: $c-blue-turquoise;
  }

  @media (max-width: 380px) {
    padding: 11px 20px!important;
  }
}

.btn.btn-grey, .btn.btn-grey.active {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  padding: 11px 30px!important;
  background: $c-pale-grey;

  &.btn-grey-thin {
    padding: 10px 30px!important;
  }
  &.btn-grey-little {
    font-size: 14px;
  }

  @media (max-width: 380px) {
    padding: 11px 20px!important;
  }
}

.btn.btn-blue:hover {
  opacity:1;
}

.btn.btn-blue:hover {
  opacity:1;
}

.btn-login-blue {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 10px 50px!important;
  background: $c-dark-indigo;
  box-shadow: 0 10px 20px 0 rgba(24, 12, 80, 0.37);
  border: solid 2px $c-dark-indigo;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (max-width: 380px) {
    padding: 10px 15px!important;
  }

  &:hover {
    color: white;
  }
}

.btn-border-login-blue {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background: $c-dark-indigo;
  border: solid 2px $c-dark-indigo;
  background: transparent;
  color: $c-dark-indigo;
  padding: 10px 50px!important;

  @media (max-width: 575px) {
    width: 100%;
  }

  @media (max-width: 380px) {
    padding: 10px 15px!important;
  }
}


.btn.btn-blue-light, .btn.btn-blue-light.active {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 30px!important;
  background: $c-vivid-blue;

  &.btn-blue-light-thin {
    padding: 10px 30px!important;
  }

  @media (max-width: 380px) {
    padding: 11px 20px!important;
  }
}

.btn.btn-blue-light:hover {
  opacity:1;
}

.btn.a2fm-btn-blue-fonce, .btn.a2fm-btn-blue-fonce.active {
  color:white;
  background:#180c50;
}

.btn.a2fm-btn-blue-fonce:hover {
  background:#180c50;
}

.btn.btn-blanc, .btn.btn-blanc.active {
  font-family: 'Open Sans', sans-serif;
  font-weight:400;
  font-size:14px;
  color:#494949;
  background:#f7f7f7;
  text-transform: uppercase;
  transition:0.3s all ease;
  user-select:none;
  opacity:1;
}

.btn.btn-blanc:hover {
  background:#180c50;
  color:white;
}

.btn.btn-orange, .btn.btn-orange.active {
  font-family: 'Open Sans', sans-serif;
  font-weight:400;
  font-size:14px;
  color:white;
  background:#d47c78;
  text-transform: uppercase;
  transition:0.3s all ease;
  user-select:none;
  opacity:1;
}

.btn.btn-orange:hover {
  opacity:1;
}

.btn.btn-purple, .btn.btn-purple.active {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 30px!important;
  background: $c-light-eggplant;

  @media (max-width: 380px) {
    padding: 11px 20px!important;
  }
}

.btn.btn-purple:hover {
  opacity:1;
}

.btn.btn-pink, .btn.btn-pink.active {
  font-family: 'Open Sans', sans-serif;
  font-weight:400;
  font-size:14px;
  color:white;
  background:#c31880;
  text-transform: uppercase;
  transition:0.3s all ease;
  user-select:none;
  opacity:1;
}

.btn.btn-pink:hover {
  opacity:1;
}

.btn.btn-yellow, .btn.btn-yellow.active {
  font-family: 'Open Sans', sans-serif;
  font-weight:400;
  font-size:14px;
  color:white;
  background:#ee8d38;
  text-transform: uppercase;
  transition:0.3s all ease;
  user-select:none;
  opacity:1;
}

.btn.btn-yellow:hover {
  opacity:1;
}

.btn.btn-filter {
  min-width:102px;
  background:#b7b7b7;
}

.btn {
  &.btn-border-white {
    border: 2px solid white;
    background: transparent;
    color: white;
    padding: 10px 20px!important;
    transition: all ease 0.4s;

    i {
      font-size: 27px;
    }

    &:hover {
      background: white;
      color: $c-blue-turquoise!important;
      opacity: 1!important;

      i {
        color: $c-blue-turquoise;
      }
    }
  }
  &.btn-border-blue-dark {
    border: 2px solid $c-dark-indigo;
    background: transparent;
    color: $c-dark-indigo!important;
    padding: 10px 20px!important;
    transition: all ease 0.4s;

    i {
      font-size: 21px;
    }

    &:hover {
      background: $c-dark-indigo;
      color: white!important;
      opacity: 1!important;

      i {
        color: white;
      }
    }
    &.logout {
      padding: 8px!important;
    }
  }
  &.btn-border-blue {
    border: 2px solid $c-blue-turquoise;
    background: transparent;
    color: $c-blue-turquoise;
    padding: 10px 20px!important;
    font-size: 14px;
    text-transform: uppercase;

    i {
      font-size: 27px;
    }

    &.logout {
      padding: 8px!important;;
    }
    &.btn-mobile {
      font-size: 13px;
      padding: 8px 18px!important;
    }
  }
}




/*-----------*/
/** BUNDLES **/
/*-----------*/

#eu-cookie-law {
  background: #ebeffd;
  font-family: 'Open Sans';
  color: $c-gris-brown;
  padding:10px;
  font-size:15px;
  transition:0.5s all ease;
  bottom:-66px;
  min-height:66px;
  box-shadow: 0 11px 25px 0 black;
  z-index: 999999;
}

#eu-cookie-law-accept {
  background: $c-dark-indigo;
  border-radius: 0;
  height: 100%;
  display: inline-block;
  padding: .8em 1.4em;
  color:white;
  cursor:pointer;
  text-decoration:none;
  transition:0.3s ease all;
  text-transform: uppercase;
  margin: 6px 10px;

  @media (max-width: 767px) {
    display: block;
    margin: 6px auto 0;
  }
}

#eu-cookie-law-accept:hover {
  opacity:1;
}

.txt-mentions-rgpd {
  color:#A3A3A3;
  font-size:12px;
  display:inline-block;
  line-height:15px !important;
}

label.required::after {
  content:' *';
  color:#cc0000;
}

.radio label.required::after {
  content:'';
}

.before-time-selector {
  display:inline-block;
  margin:0px 8px;
}


.a2fm-request-bloc {
  color:$c-gris-brown;
  font-weight:300;
  font-family: 'Open Sans';
}

.a2fm-request-bloc h2 {
  font-family: 'Open Sans';
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $c-dark-indigo;
  font-weight: bold;
}

.a2fm-request-bloc p {
  color:$c-gris-brown;
  font-weight:300;
  font-family: 'Open Sans';
}

.alert-info {
  color: #180c50;
  background-color: #ffffff;
  border-color: #180c50;
}


.public .modal-content {
  border-radius:4px;
  border:0;
}

.public .modal-title {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 20px;
  color: $c-dark-indigo;
}

.public .modal-body {
  font-weight: 300;
  font-family: 'Open Sans';
  font-size:15px;

  b {
    font-weight: bold;
  }
}

.public .modal-content .btn-secondary {
  color: #494949 !important;
  background: #eaeaea !important;
}

.public .modal-content .btn-secondary:hover {
  background: #eaeaea !important;
  color: #494949 !important;
}

.public .modal-content .btn-primary {
  background:$c-dark-indigo;
}

.public .modal-content .btn-primary:hover {
  opacity:1;
}

.public .modal-content .btn {
  padding: 8px 18px;
}

.a2fm-text-help-small {
  font-size:12px;
}

.alert {
  border: 0;
  color: white;

  &.alert-danger {
    background: #e0421d;
  }
  &.alert-warning {
    background: #f7b500;
  }
  &.alert-info {
    background: #0091ff;

    /*&:before {
      content: "\f05a";
      font-family: Font Awesome\ 5 Free;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }*/
  }
  &.alert-success {
    background: #68d69b;
  }

  .close {
    color: white;
    opacity: 1;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: ArialRounded!important;
}

table.dataTable {
  outline:none !important;
}

table.dataTable.no-footer {
  border-bottom:0;
}

table.dataTable tbody tr:first-child td {
  border-top:0;
}

table.dataTable thead th,
table.dataTable thead td {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  padding: .75rem;
}

.color-dpc {
  color: #04afde;
}

.color-faf {
  color: #d13990;
}

.color-autre {
  color: #180c50;
}

#newsletter {
  h2 {
    font-family: ArialRounded!important;
    font-size: 36px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    color: #180c50;
  }
  h3 {
    font-family: Open Sans,sans-serif!important;
    font-size: 15px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #d13990;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    outline: none!important;
  }
}

.tooltip.bs-tooltip-right .arrow {
  display: none;
}

.tooltip.bs-tooltip-top {
  position: relative;

  .arrow {
    top: auto;
    bottom: 2px;
    transform: rotate(180deg);
    border-bottom-color: black;

    &:before {
      display: none;
    }
  }
}


.a2fm-desc-info-format {
  span {
    &:last-child {
      display: none;
    }
  }
}

.text-decoration-none {
  text-decoration: none!important;
}

.force-img-100 img {
  width: 100%!important;
  height: auto!important;
}

@import "./header/header";
@import "./home/home";
@import "./tunnel-formation/styles";
@import "./formations/formations";
@import "./presentation/presentation";
@import "./articles/articles";
@import "./login/login";
@import "./contenu/contenu";
@import "./footer/footer";
@import "./gabarit/gabarit";

@import "./admin/admin";
